import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faExternalLinkAlt, faTimesCircle, faCheckCircle,faCheck, faFolder,faEnvelope, faTemperatureHigh } from "@fortawesome/free-solid-svg-icons";
import { faFacebook,faWhatsapp, faGithub } from "@fortawesome/free-brands-svg-icons";
import { Form, Col, Row, Card, Image, Button, Container, ListGroup, Tooltip, OverlayTrigger, Navbar, Nav, Spinner, Badge } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { Routes } from "../routes";
import MockupPresentation from "../assets/img/mockup-presentation.png";
import FieldConnectPresentation1 from "../assets/img/presentation1.png";
import FieldConnectMobile from "../assets/img/presentation2.png"; 
import FieldConnectMap from "../assets/img/presentation3.png"; 
import FieldConnectLogo from "../assets/img/logo.png";
import { toast, Toaster } from 'react-hot-toast'; 
import features from "../data/features";

export default () => {
  const [emailNewsLetter, setEmailNewsLetter] = useState('');
  const [pricing, setPricing] = useState("monthly")
  const [inLoading, setInLoading] = useState(false)

  const handleNewsLetter = (e) => {
    e.preventDefault()
    let reg =   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


    if(emailNewsLetter != "" && reg.test(emailNewsLetter) ) {
      setInLoading(faTemperatureHigh)
      fetch(`/api/newsletter`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({emailNewsLetter:emailNewsLetter})}).then(
        response => response.json()
      ).then(
        response => {
            setInLoading(false)
           if(response.status == "success") {
            setEmailNewsLetter("")
            toast.success(response.message, {
              style: {
                border: '1px solid #713200',
                padding: '16px',
              }});
           } else {
            toast.error(response.message, {
              style: {
                border: '1px solid #713200',
                padding: '16px',
              }});
           }
            
        }
      )
    } else {
      toast.error('Veuillez saisir une adresse email valide.', {
        style: {
          border: '1px solid #713200',
          padding: '16px',
        }});
    }
  }
  const PagePreview = (props) => {
    const { name, image, link } = props;

    return (
      <Col xs={6} className="mb-5">
        <Card.Link as={Link} to={link} className="page-preview page-preview-lg scale-up-hover-2">
          <Image src={image} className="shadow-lg rounded scale" alt="Dashboard page preview" />

          <div className="text-center show-on-hover">
            <h6 className="m-0 text-center text-white">
              {name} <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-2" />
            </h6>
          </div>
        </Card.Link>
      </Col>
    );
  };

  const Feature = (props) => {
    const { title, description, icon } = props;

    return (
      <Col xs={12} sm={6} lg={3}>
        <Card className="bg-white shadow-soft text-primary rounded mb-4">
          <div className="px-3 px-lg-4 py-5 text-center">
            <span className="icon icon-lg mb-4">
              <FontAwesomeIcon icon={icon} />
            </span>
            <h5 className="fw-bold text-primary">{title}</h5>
            <p>{description}</p>
          </div>
        </Card>
      </Col>
    );
  };

  const FolderItem = (props) => {
    const { name, icon, tooltip, iconColor } = props;
    const color = iconColor ? `text-${iconColor}` : "";

    return (
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={<Tooltip>{tooltip}</Tooltip>}
      >
        <li data-toggle="tooltip" data-placement="left" title="Main folder that you will be working with">
          <FontAwesomeIcon icon={icon ? icon : faFolder} className={`${color} me-2`} /> {name}
        </li>
      </OverlayTrigger>
    );
  };

  return (
    <>
      <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image style={{height:50}} src={FieldConnectLogo} />
            {/* <span className="ms-2 brand-text d-none d-md-inline">FieldConnect</span> */}
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="#presentation">Présentation</Nav.Link>
                <Nav.Link as={HashLink} to="#features">Fonctionnalités</Nav.Link>
                <Nav.Link as={HashLink} to="#pricing" className="d-sm-none d-xl-inline">Abonnement </Nav.Link>
                <Nav.Link as={HashLink} to="#contact">Nous contacter</Nav.Link>
                {/* <Nav.Link as={HashLink} to="#download">Tarification</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            <Button style={{marginRight: 10, marginLeft: 50}} as={Link} to={Routes.Signin.path} variant="outline-white" className="ms-3">Espace client</Button>
            <Button variant="secondary" as={Link} to={Routes.Signup.path} className="text-dark me-3">
            Essayer gratuitement 
            {/* <FontAwesomeIcon icon={faExternalLinkAlt} className="d-none d-sm-inline ms-1" /> */}
                </Button>
            
          </div>
        </Container>
      </Navbar>
      <section className="section-header overflow-hidden pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary text-white" id="home">
        <Container>
          <Row>
            <Col xs={12} className="text-center">
              {/* <div className="react-big-icon d-none d-lg-block"><span className="fab fa-react"></span></div> */}
              <h1 className="text-secondary">Bienvenue sur FieldConnect</h1>
              <p className="mb-4 ">La solution idéale pour vos bésoins de création de formulaire dynamique,  qui peut s'adapter à toutes les spécificités de  <br/>vos activités de collecte de données sur terrain.</p>
              <div className="d-flex align-items-center justify-content-center">
                {/* <Button variant="secondary" as={Link} to={Routes.LoginPage.path} className="text-dark me-3">
                  Démarrer maintenant <FontAwesomeIcon icon={faExternalLinkAlt} className="d-none d-sm-inline ms-1" />
                </Button> */}
              </div>
              {/* <div className="d-flex justify-content-center flex-column mb-6 mb-lg-5 mt-5">
                <div className="text-center">
                  <a href="https://themesberg.com" target="_blank">
                    <Image src={ThemesbergLogoIcon} height={25} width={25} className="mb-3" alt="Themesberg Logo" />
                    <p className="text-muted font-small m-0">A Themesberg production</p>
                  </a>
                </div>
              </div> */}
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>
        </Container>
      </section>
      <div id="presentation" className="section pt-0">
        <Container className="mt-n10 mt-lg-n12 z-2">
          <Row className="justify-content-center">
            <Col xs={12}>
              <Image style={{marginTop:"10px"}} src={MockupPresentation} alt="Mockup presentation" />
            </Col>
          </Row>
          
        </Container>
      </div>
      <section className="section section-md bg-soft pt-lg-3" >
        <Container>
          <Row  className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2>Pourquoi FieldConnect ? </h2>
              <p className="mb-3 lead fw-bold">FieldConnect vous offre la possibilité de gérer vos formulaires dynamiquement</p>
              <p className="mb-4">Parmi tant d'autres solutions de collectes de données qui existent, FieldConnect vous permet de créer et éditer vous-même vos formulaires avec plusieurs possibilités de champs pour combler vos besoins.</p>
              {/* <Button as={Link} to={Routes.DashboardOverview.path} variant="secondary" target="_blank">Live Demo <FontAwesomeIcon icon={faExternalLinkAlt} className="ms-1" /></Button>
              <Button as={HashLink} to="#download" variant="outline-primary" className="ms-3"><FontAwesomeIcon icon={faShoppingCart} className="me-1" /> Download</Button> */}
            </Col>
            <Col lg={6} className="order-lg-1">
              <Image src={FieldConnectPresentation1} alt="Calendar Preview" />
            </Col>
          </Row>
          </Container>
          </section>

          <section id="features" className="section section-md bg-primary text-white">
            <Container>
              <Row className="justify-content-center mb-5 mb-lg-6">
                <Col xs={12} className="text-center">
                  <h2 className="px-lg-5"> Fonctionnalités principales</h2>
                  <p className="lead px-lg-8">Améliorez votre productivité en profitant les fonctionnalités à la pointe de la technologie offert par FieldConnect</p>
                </Col>
              </Row>
              <Row>
                {features.map(feature => <Feature key={`features-${feature.id}`} {...feature} />)}
              </Row>
            </Container>
          </section>

        <section  className="section-md bg-soft pt-lg-3" >
          <Container>
         
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2 className="d-flex align-items-center">FieldConnect Tracking GPS</h2>
              <p className="mb-3 lead fw-bold">Suivez les activités de vos travailleurs sur terrain</p>
              <p className="mb-4">Nous vous offrons une visibilité en temps réel des activités sur terrain pour que vous puissiez avoir tous les moyens nécessaires pour méner à bien vos activités. </p>
              {/* <Button href="https://demo.themesberg.com/volt-pro-react/#/map" className="me-3" variant="secondary" target="_blank"><FontAwesomeIcon icon={faMapMarkedAlt} className="me-2" /> Demo Map</Button>
              <Button href="https://demo.themesberg.com/volt-pro-react/#/plugins/map" variant="outline-primary" target="_blank"><FontAwesomeIcon icon={faBook} className="me-2" /> Guide</Button> */}
            </Col>
            <Col lg={6} className="order-lg-1">
              <Image className="shadow" style={{marginTop: 100}} src={FieldConnectMap} alt="MapBox Leaflet.js Custom Integration Mockup" />
            </Col>
          </Row>
          <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5}>
              <h2>FieldConnect Mobile</h2>
              <p className="mb-3 lead fw-bold">Toutes vos formulaires seront converties en interface native sur les smartphones</p>
              <p className="mb-4">Pour que vos expériences sur terrain soient parfaites, Nous avons mis à votre disposition une application mobile puissante, native, ergonomique et simple à utiliser.</p>
              <p className="mb-4">En plus de cela, l'application vous  permet d'effectuer vos activités même hors connexion. </p>
            </Col>
            <Col lg={6} className="pt-3">
            <Image src={FieldConnectMobile} alt="Calendar Preview" />
             
            </Col>
          </Row>
          {/* <Row className="justify-content-between align-items-center mb-5 mb-lg-7">
            <Col lg={5} className="order-lg-2 mb-5 mb-lg-0">
              <h2 className="d-flex align-items-center">FieldConnect IA</h2>
              <p className="mb-3 lead fw-bold">Profitez de l'Intelligence Artificielle pour une reconnaissance de vos marques</p>
              <p className="mb-4">Optimisez l'efficacité de la saisie des données grâce à la reconnaissance d'images qu'on vous met à disposition</p>
             </Col>
            <Col lg={6} className="order-lg-1">
              <Image src={MapboxImg} alt="MapBox Leaflet.js Custom Integration Mockup" />
            </Col>
          </Row> */}
          
         
        </Container>
      </section>
      
    
      
      <section className="section section-lg bg-white" id="pricing">
        <Container>
          <Row>
            <Col xs={12} lg={8}>
              <h2 className="d-flex align-items-center mb-3">Abonnement</h2>
              <p className="lead mb-4 me-lg-6">Souscrivez à un abonnement pour profitez pleinement nos offres</p>
            </Col>
            <Col xs={12} lg={4}>
              <div className="github-big-icon">
                
              </div>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col xs={12} lg={5}>
              <Row>
                <Col onClick={() => setPricing("monthly")}  style={{ textDecoration: pricing == "monthly" ? "underline" : "none",textUnderlineOffset: pricing == "monthly" ? "6px" : "0px", cursor:"pointer"  }} xs={12} lg={4}>
                
                  <div style={{ display: "flex", alignItems: "center" }}>
                      {
                        pricing == "monthly" ? (
                          <FontAwesomeIcon style={{fontSize:"15px"}} icon={faCheck} />
                        ): (
                          <span />
                        )
                      }
                    <span style={{ fontWeight: "bold", marginLeft: "8px", fontSize:"16px", color:"#191c2a" }}>Mensuel</span>
                  </div>
                </Col>
                <Col onClick={() => setPricing("yearly")} style={{ textDecoration: pricing == "yearly" ? "underline" : "none",textUnderlineOffset: pricing == "yearly" ? "6px" : "0px", cursor:"pointer"  }}  xs={12} lg={8}>
                    <div style={{ display: "flex", alignItems: "center", marginLeft:"20px" }}>
                      {
                        pricing == "yearly" ? (
                          <FontAwesomeIcon style={{fontSize:"15px"}} icon={faCheck} />
                        ): (
                          <span />
                        )
                      }
                      <span style={{fontWeight:"bold", marginLeft: "8px", fontSize:"16px", color:"#191c2a"}}>Annuel (économisez 20%)</span>
                    </div>
                  
                </Col>
              </Row>
            
            </Col>
            
          </Row>
          
          <Row className="mt-6">
            <Col xs={12} md={6} lg={4} className="mb-5 mb-lg-0">
              <Card border="light" className="p-4">
                <Card.Header className="bg-white border-0 pb-0">
                  <span className="d-block">
                    <h2 className="text-primary fw-bold align-top">Basique</h2>
                    <p className=" mb-4 ">{pricing == "monthly" ? "9.000" : "7.200"} Ar TTC/mois/utilisateur</p>
                  </span>
                </Card.Header>
                <Card.Body>
                  <ListGroup className="list-group-flush price-list">
                    
                    {/* <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Stockage Illimité
                    </ListGroup.Item> */}
                    
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 5 Formulaires
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 10 champs par formulaire
                    </ListGroup.Item>
                    
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 20 000 soumissions/mois
                    </ListGroup.Item>


                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 1 Equipe
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-danger me-2" /> API récuperation données
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-danger me-2" /> Maps avancés
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-danger me-2" /> FieldConnect IA
                    </ListGroup.Item>
                    
                    {/* <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-danger me-2" /> SMS Broadcast
                    </ListGroup.Item> */}
                    
                  </ListGroup>
                </Card.Body>
                <p style={{fontWeight:"bold", marginLeft:"10px"}} className=" mb-4 ">* Essai gratuit pendant 30 jours</p>
                <Button as={HashLink} to="/#contact" variant="primary" className="w-100 m-0 mt-3 mb-3"> Nous contacter</Button>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={4} className="mb-5 mb-lg-0">
              <Card border="light" className="p-4 py-5 mt-lg-n5">
                <Card.Header className="bg-white border-0 pb-0">
                  <span className="d-block">
                      
                      <Row>
                        <Col>
                        <h2 className="text-primary fw-bold align-top">Standard </h2>
                      
                        </Col>
                        <Col style={{marginTop:10,marginLeft:-15}}>
                        <Badge bg="success" className="badge-lg">Populaire</Badge>
                        </Col>
                      </Row>
                    
                    <p className=" mb-4">{pricing == "monthly" ? "19.000" : "15.200"}Ar TTC/mois/utilisateur</p>
                    
                  </span>
                </Card.Header>
                <Card.Body>
                  <ListGroup className="list-group-flush price-list">
                    {/* <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>200+</strong> React Components
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>20</strong> Example Pages
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <strong>6</strong> Plugins
                    </ListGroup.Item> */}
                    {/* <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Stockage Illimité
                    </ListGroup.Item> */}

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 25 Formulaires
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 50 champs par formulaire
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 50 000 soumissions/mois
                    </ListGroup.Item>
                    
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> 5 Equipes
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> API récuperation données
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Maps avancés
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-danger me-2" /> FieldConnect IA
                    </ListGroup.Item>
                 
                    {/* <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faTimesCircle} className="text-danger me-2" /> SMS Broadcast
                    </ListGroup.Item> */}
                  </ListGroup>
                </Card.Body>
                <p style={{fontWeight:"bold", marginLeft:"10px"}} className=" mb-4 ">* Essai gratuit pendant 30 jours</p>
                <Button as={HashLink} to="/#contact" variant="primary" className="w-100 m-0 mt-3 mb-3"> Nous contacter</Button>
              </Card>
            </Col>


            <Col xs={12} md={6} lg={4} className="mb-5 mb-lg-0">
              <Card border="light" className="p-4">
                <Card.Header className="bg-white border-0 pb-0">
                  <span className="d-block">
                    <h2 className="text-primary fw-bold align-top">Entreprise</h2>
                    <p className=" mb-4 ">{pricing == "monthly" ? "39.000" : "31.200"}Ar TTC/mois/utilisateur</p>
                  </span>
                </Card.Header>
                <Card.Body>
                  <ListGroup className="list-group-flush price-list">
                    
                    {/* <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Stockage Illimité
                    </ListGroup.Item> */}
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Formulaires Illimités
                    </ListGroup.Item>

                   

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Champs  Illimités
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />  soumissions/mois Illimités
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Equipes  Illimités
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> API récuperation données
                    </ListGroup.Item>

                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> Maps avancés
                    </ListGroup.Item>

                    
                    <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> FieldConnect IA
                    </ListGroup.Item>
                    
                    {/* <ListGroup.Item className="bg-white border-0 ps-0">
                      <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" /> SMS Broadcast
                    </ListGroup.Item> */}
                  </ListGroup>
                </Card.Body>
                <p style={{fontWeight:"bold", marginLeft:"10px"}} className=" mb-4 ">* Essai gratuit pendant 30 jours</p>
                <Button as={HashLink} to="/#contact"  variant="primary" className="w-100 m-0 mt-3 mb-3"> Nous contacter</Button>
              </Card>
            </Col>
           
          </Row>
          
        </Container>
      </section>

      <section id="contact" className="section section-md bg-primary text-white">
        <Container>
          <Row className="justify-content-center mb-5 mb-lg-6">
            <Col xs={12} className="text-center">
              <h2 className="px-lg-5"> Nous contacter</h2>
              <p className="lead px-lg-8">N'hésitez surtout pas à nous contacter si vous avez la moindre question.</p>
            </Col>
          </Row>
          
        </Container>
      </section>

      <section className="section section-lg bg-white" >
        <Container>
          <Row className="justify-content-center mt-5 mt-lg-6">
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faEnvelope} className="text-secondary" />
              </div>
              <p className="text-gray">Email</p>
              <h3 className="fw-bolder">info@e-fieldconnect.com</h3>
              
            </Col>
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faWhatsapp} className="text-secondary" />
              </div>
              <p className="text-gray">Téléphone</p>
              <h3 className="fw-bolder">+261 38 72 160 52</h3>
              
            </Col>
            {/* <Col xs={6} md={3} className="text-center">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon icon={faFacebook} className="text-secondary" />
              </div>
              <p className="text-gray">Facebook</p>
              <h3 className="fw-bolder">FieldConnect Officiel</h3>
            </Col> */}
            {/* <Col xs={6} md={3} className="text-center">
              <div className="icon icon-shape icon-lg bg-white shadow-lg border-light rounded-circle mb-4">
                <FontAwesomeIcon color="secondary" icon={faBootstrap} className="text-secondary" />
              </div>
              <h3 className="fw-bolder">Bootstrap 5</h3>
              <p className="text-gray">CSS Framework</p>
            </Col> */}
          </Row>
          
          
        </Container>
      </section>


      <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 mb-3 d-flex align-items-center">
                
                <Image style={{height:50}} src={FieldConnectLogo} />
              </Navbar.Brand>
              <p>La solution idéale pour vos bésoins de création de formulaire dynamique,  qui peut s'adapter à toutes les spécificités de vos activités de collecte de données sur terrain.</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Navigation</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link as={HashLink}  to="#presentation">Présentation</Card.Link></li>
                <li><Card.Link as={HashLink}  to="#features">Fonctionnalités</Card.Link></li>
                <li><Card.Link as={HashLink} to="#pricing">Abonnement</Card.Link></li>
                <li><Card.Link as={HashLink}  to="#">Nous contacter</Card.Link></li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Autres</span>
              <ul className="links-vertical mt-2">
                <li>
                  {/* <Card.Link as={Link} to={Routes.DocsQuickStart.path} target="_blank">Politique de confidentialité</Card.Link> */}
                </li>
                {/* <li><Card.Link as={Link} to={Routes.DocsChangelog.path} target="_blank">Termes et Conditions</Card.Link></li> */}
                <li><Card.Link  as={Link} to={Routes.TermsAndConditions.path} target="_blank"  >Termes et Conditions</Card.Link></li>
                <li><Card.Link  as={Link} to={Routes.Privacy.path} target="_blank"  >Politique de confidentialité</Card.Link></li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">La newsletter</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input value={emailNewsLetter} onChange={(e) => setEmailNewsLetter(e.target.value)}  type="email" className="form-control mb-2" placeholder="example@company.com" name="email" aria-label="Subscribe form" required />
                  </div>
                  <div className="col-12">
                    <button onClick={(e) => handleNewsLetter(e)} type="submit" className="btn btn-secondary text-dark shadow-soft btn-block" data-loading-text="Sending">
                      <span>
                      {
                      inLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Souscrire"
                      )
                    }
                      </span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">Souscrivez sur notre Newsletter pour ne pas manquer tous les mises à jours.</p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © FieldConnect 2024. Tous droits réservés.</p>
              </div>
              <Card.Link href="https://vntechnology.mg" target="_blank" className="d-flex justify-content-center">
                {/* <Image src={ThemesbergLogo} height={35} className="d-block mx-auto mb-3" alt="Themesberg Logo" /> */}
                <p className="font-weight-normal font-small mb-2">Powered by <span style={{color:"#61DAFB"}} className="ms-2 brand-text">VNTechnology</span>.</p>

              </Card.Link>
            </Col>
          </Row>
        </Container>
      </footer>
      <Toaster   
          position="top-right"
          reverseOrder={true} />
    </>
  );
};
