import React, {useState, useEffect,useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome,faPlus,faEdit,faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb,Button,Col, Row , Nav, Card, Table, Pagination,Form } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link,useHistory  } from 'react-router-dom';
// import { useUser } from '../components/userContext';
// import withReactContent from 'sweetalert2-react-content';
import { Toaster } from 'react-hot-toast'; 
import DataTable from 'react-data-table-component';
import { CSVLink } from 'react-csv';
import { useAuth } from './AuthContext';
import { ClipLoader } from "react-spinners";

export default () =>  {
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [user, setUser] = useState([])
  const userCon =  JSON.parse(localStorage.getItem("user"))
  const [myrole, setMyrole] = useState('');
  const { token } = useAuth();

  const columns = [
    {
      name: '',
      selector: row =>(
                    row.image == undefined ? 
                    <div className="avatar-sm bg-secondary d-flex align-items-center justify-content-center rounded me-3">
                        <small className="fw-bold p-1">
                    {getInitials(row.name+" "+row.firstName)}
                    </small></div>: <><img src={row.image} alt="Photo" class="avatar-sm rounded-circle me-3"/> </> 
                    
      ) ,
      sortable: true,
      width: '60px'
    },
    {
      name: 'NOM',
      selector: row => <Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/update-user/${row._id}`}} > {row.name}</Card.Link>,
      width: '200px'
    },
    {
      name: 'PRÉNOM',
      selector: row => row.firstName,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'NUMÉRO',
      selector: row => row.phoneNumber,
      sortable: true,
    },
    {
      name: 'EQUIPE',
      selector: row => row.team.map(team => team.name).join(', '),
      sortable: true,
    },
    {
      name: 'RESPONSABLE',
      selector: row => row.leadId == undefined ?'': row.leadId.firstName ,
      sortable: true,
    },
    {
      name: 'PLATEFORME',
      selector: row => row.plateforme,
      sortable: true,
    },
    {
      name: 'GENRE',
      selector: row => row.gender,
      sortable: true,
    },
    {
      name: 'RÔLE',
      selector: row => row.role,
      sortable: true,
    },
    {
      name: 'ACTION',
      selector: row =>  (row._id == userCon._id || (myrole == 'RESPONSABLE' && ['RESPONSABLE','ADMIN'].includes(row.role))  )? (''):(<>
                        <Link to={{ pathname: `/update-user/${row._id}`, state: { user : row.data } }}>
                          <FontAwesomeIcon  icon={faEdit} className="me-2" />
                        </Link> 
                        <Link onClick={() => delUser(row._id,row.email,row.phoneNumber)}>
                              <FontAwesomeIcon  className="text-danger me-2" icon={faTrashAlt} /> 
                        </Link>
                      </>),
      sortable: true,
    }
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold',
        backgroundColor: '#1f2334', 
        color : 'white'// You can set your desired background color here
      },
    },
  };
useEffect(() => {
    
      fetch(`/api/getUser/${userCon._id}`).then(
      response => response.json()
    ).then(
      response => {
        setMyrole(response[0].role)
      }
)}, [userCon])

useEffect( 
    ()=> {
      fetch(`/api/getAllUsers/${userCon._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(response => response.json())
        .then(data => {
          setUser(data["users"]);
          // console.log('user con'+ JSON.stringify(data))
        })
        .catch(error => {
          console.error('Error:', error);
        })
}, [userCon])



const getInitials = (fullName) => { 
    const nameArray = fullName.split(' ');
    const initials = nameArray.map(name => name.charAt(0).toUpperCase()).join('');
    return initials;
};
const delUser = (id,email,phoneNumber) => {
      
    swal({
      title: "Confirmation",
      text: "Voulez-vous supprimer définitivement cet utilisateur ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((test) => {
      if (test) {
        fetch('/api/deleteUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
          body: JSON.stringify({id,email,phoneNumber}),
        }).then(response => response.json()).then(
          data => {
            if(data.status === 'success'){
              const updatedUsers = user.filter((us) => us._id !== id);
              setUser(updatedUsers);
              swal(data['message'] + "!", {
                icon: "success",
              });
            }else{
              swal(data['message'], {
                icon: "error",
              });
            }
            
          }
        )
      } 
    });
  }
  const filteredData = useMemo(() => {
    return user.filter(
		item => (item.name && item.name.toLowerCase().includes(filterText.toLowerCase()))  || 
        (item.email && item.email.toLowerCase().includes(filterText.toLowerCase())) ||  
        (item.firstName && item.firstName.toLowerCase().includes(filterText.toLowerCase())) ||
        (item.phoneNumber && item.phoneNumber.toLowerCase().includes(filterText.toLowerCase()))
	)
  }, [filterText,user]);

  const csvData = useMemo(() => {
    return filteredData.map(row => ({
      _id: row._id,
      name: row.name,
      firstName: row.firstName,
      email: row.email,
      phoneNumber: row.phoneNumber,
      team: row.team.map(team => team.name).join(', '),
      plateforme: row.plateforme,
      gender: row.gender,
      role: row.role,
      image: row.image
    }));
  }, [filteredData]);

  const subHeaderComponent = useMemo(() => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
        type="text"
        placeholder="Search"
        value={filterText}
        onChange={e => {
          setFilterText(e.target.value);
          setResetPaginationToggle(!resetPaginationToggle);
        }}
      />
        <CSVLink
        data={csvData}
        filename="user_list.csv"
        className="btn btn-primary"
        style={{ marginLeft: '10px', height: '32px',padding: '4px'}}
        >
        Export CSV
        </CSVLink>
      </div>
    );
  }, [filterText, resetPaginationToggle,csvData]);

  return (
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} />FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item active>Utilisateurs</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
        <Col xs={8} md={6} lg={3} xl={4}>
        <Button variant="primary" size="sm" as={Link} to={Routes.CreateUser.path}><FontAwesomeIcon icon={faPlus} className="me-2" />Créer</Button>
        </Col>
        </Row>
      </div>
      {/* striped */}
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">

        
          {/* user.length > 0 ? ( */}
            
            <DataTable
            // title="User List"
            columns={columns}
            data={filteredData}
            pagination
            paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            customStyles={customStyles}
            subHeaderComponent={subHeaderComponent}
            // actions={actionsMemo}
    />
        
                
      </Card.Body>
    </Card>
    <Toaster   
          position="top-right"
           />
      
    </>


    
  );
};

// export default MyDataTable;
