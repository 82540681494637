
import React, { useEffect,useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'; 
import { Routes } from "../routes";


export default () => {
  const history = useHistory();
  const [inLoading, setInLoading] = useState(false)
  const routeParams  = useParams();
  const token = routeParams.token
  const [ userId, setUserId ] = useState();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const resetPassword = () => {
    if(password != confirmPassword) {
      toast.error("Les deux mot de passe ne correspondent pas.", {
        style: {
          border: '1px solid #713200',
          padding: '16px',
        }});
    } else {
      setInLoading(true)


      fetch('/api/resetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({userId,password}),
      }).then(
        response => response.json()
      ).then(
        data => {
          if (data.status === "success") {
            console.log(data)
            setInLoading(false)
            // Redirection vers la page login
            toast.success("Mot de passe mis à jour avec succès.", {
              style: {
                border: '1px solid #713200',
                padding: '16px',
              }});
            // Un message avec un lien de confirmation vous a été envoyé par mail. Veuillez suivre ce lien pour activer votre compte.
            history.push("/sign-in");
          } else {
            setInLoading(false)
            // Affichage d'un message d'erreur
            console.error(data.message);
            toast.error(data.message, {
              style: {
                border: '1px solid #713200',
                padding: '16px',
              }});
          }
        }
      );
    }
  }
  

  useEffect(() => {
    const checkTokenValidity = () => {
      
        if(token != null && token != "") {
          console.log("checking...")
          fetch('/api/checktokenvalidity', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({token}),
          }).then(
            response => response.json()
          ).then(
            data => {
              if (data.status === "success") {
                console.log(data.userId)
                setUserId(data.userId)
              } else {
                console.log("data.error...")
                // Affichage d'un message d'erreur
                history.push("/sign-in");
              }
            }
          );
        }else {
          history.push("/page-not-found/404");
        }
      
    }
    checkTokenValidity();
  }, []);

  return (
    <main>
      <div>
            <Toaster   
              position="top-right"
              reverseOrder={false} />
          </div>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à la page de connexion
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">Redéfinir mon mot de passe</h3>
                <Form>
                  
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Nouveau mot de passe</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control value={password} onChange={(e) => setPassword(e.target.value)} required type="password" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirmer mot de passe</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required type="password" />
                    </InputGroup>
                  </Form.Group>
                  <Button onClick={() => resetPassword()}  variant="primary" type="button" className="w-100">
                    
                    {
                      inLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Réinitialiser mon mot de passe"
                      )
                    }
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
