import React, {useState,useEffect,useContext} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Card, Image, Button, Container,Alert } from '@themesberg/react-bootstrap';

import { Routes } from "../../routes";
import dashboardPage from "../../assets/img/homelogo.png";
import { Link, useHistory, useParams } from 'react-router-dom';


export default () => {
const history = useHistory()
const routeParams  = useParams();
const token = routeParams.token
const [message,setMessage] = useState()
useEffect(() => {
    fetch(`/api/updateMailUser/${token}`).then(
        response => response.json()
    ).then(
        response => {
            if(response.status == 'notoken'){
                  history.push('/page-not-found/404')  
            }else{
                setMessage(response)
            }
            console.log(response)

    }
)}, [token])
  return (
    <main>
      <section className="vh-100 d-flex align-items-center justify-content-center">
        <Container>
          <Row>
            <Col xs={12} className="text-center d-flex align-items-center justify-content-center">
              <div>
                <Card.Link as={Link} to={Routes.DashboardOverview.path}>
                  <Image src={dashboardPage} className="img-fluid w-75" />
                </Card.Link>
                
                {message &&
                        <Row className="justify-content-center form-bg-image">
                            <Col xs={12} className="d-flex align-items-center justify-content-center">
                            <div className=" p-4 p-lg-2 w-100 fmxw-500">
                                <Alert  variant={message.status == 'success'?'success':'danger'} >
                                 {message.message}
                                </Alert>
                            </div>
                            </Col>
                        </Row>
                      
               }
                <Button as={Link} variant="primary" className="animate-hover" to={Routes.Presentation.path}>
                  <FontAwesomeIcon icon={faChevronLeft} className="animate-left-3 me-3 ms-2" />
                  Retour à la page d'accueil
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
