import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router,Route, Switch, Redirect } from "react-router-dom";
import { Routes } from "../routes";

// pages
import Presentation from "./Presentation";
import Upgrade from "./Upgrade";
import DashboardOverview from "./dashboard/DashboardOverview";
import Prospection from "./Prospection";
import MapPage from './MapPage';
import NewProspection from "./NewProspection";
import ViewProspection from "./ViewProspection";
import CreateForms from "./CreateForms";
import ModifyForms from "./ModifyForms";
import TeamTable from "./TeamTable";

import UserTables from "./UserTables";
import UpdateUser from "../components/UserManagement/UpdateUser";
import CreateUser from "../components/UserManagement/CreateUser";

import UpdateTeam from "../components/TeamManagement/UpdateTeam";


import Signin from "./Signin";
import Signup from "./Signup";
import Carto from "./Carto";
import TermsAndConditions from "./TermsAndConditions";
import Privacy from "./Privacy";

import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import ConfirmNewsLetter from "./ConfirmNewsLetter";
import Lock from "./examples/Lock";
import NotFoundPage from "./examples/NotFound";
import ServerError from "./examples/ServerError";
import SuccessGoogleSignIn from './SuccessGoogleSignIn';
import ChangeMail from "./examples/ChangeMail";

// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";


// import LoginPage from "./LoginPage";

import { AuthProvider,useAuth } from './AuthContext';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route {...rest} render={props => ( <> <Preloader show={loaded ? false : true} /> <Component {...props} /> </> ) } />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);
  const { token } = useAuth();

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    // return localStorage.getItem('settingsVisible') === 'false' ? false : true
    return false
  }

  const [showSettings, setShowSettings] = useState(localStorageIsSettingsVisible);

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem('settingsVisible', !showSettings);
  }

  return (  
    <Route {...rest} 
    render={  
      props => (
      token ?
      <>
        <Preloader show={loaded ? false : true} />
        <Sidebar />
        <main className="content">
          <Navbar />
          <Component {...props} />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </main>
      </> : <Redirect to="/sign-in" />
    )}
    />
  );
};

export default () => (
  <AuthProvider>
  {/* <Router> */}
  <Switch>
    {/* <RouteWithLoader exact path={Routes.LoginPage.path} component={LoginPage} /> */}
    <RouteWithLoader exact path={Routes.Presentation.path} component={Presentation} />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.Signup.path} component={Signup} />
    <RouteWithLoader exact path={Routes.ForgotPassword.path} component={ForgotPassword} />
    <RouteWithLoader exact path={Routes.ResetPassword.path} component={ResetPassword} />
    <RouteWithLoader exact path={Routes.ConfirmNewsLetter.path} component={ConfirmNewsLetter} />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader exact path={Routes.ChangeMail.path} component={ChangeMail} />

    <RouteWithLoader exact path={Routes.NotFound.path} component={NotFoundPage} />
    <RouteWithLoader exact path={Routes.ServerError.path} component={ServerError} />
    <RouteWithLoader exact path={Routes.SuccessGoogleSignIn.path} component={SuccessGoogleSignIn} />
    <RouteWithLoader exact path={Routes.TermsAndConditions.path} component={TermsAndConditions} />
    <RouteWithLoader exact path={Routes.Privacy.path} component={Privacy} />

    {/* pages */}
    <RouteWithSidebar exact path={Routes.DashboardOverview.path} component={DashboardOverview} />
    <RouteWithSidebar exact path={Routes.Upgrade.path} component={Upgrade} />
    <RouteWithSidebar exact path={Routes.Prospection.path} component={Prospection} />
    <RouteWithSidebar exact path={Routes.NewProspection.path} component={NewProspection} />
    <RouteWithSidebar exact path={Routes.ViewProspection.path} component={ViewProspection} />
    <RouteWithSidebar exact path={Routes.CreateForms.path} component={CreateForms} />
    <RouteWithSidebar exact path={Routes.CreateUser.path} component={CreateUser} />
    <RouteWithSidebar exact path={Routes.ModifyForms.path} component={ModifyForms} />
    <RouteWithSidebar exact path={Routes.UpdateUser.path} component={UpdateUser} />
    <RouteWithSidebar exact path={Routes.TeamTable.path} component={TeamTable} />

    <RouteWithSidebar exact path={Routes.UpdateTeam.path} component={UpdateTeam} />


    <RouteWithSidebar exact path={Routes.UserTables.path} component={UserTables} />
    <RouteWithSidebar exact path={Routes.Map.path} component={MapPage} />
    
    {/* components */}
    {/* <RouteWithSidebar exact path={Routes.Accordions.path} component={Accordion} />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar exact path={Routes.Breadcrumbs.path} component={Breadcrumbs} />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar exact path={Routes.Pagination.path} component={Pagination} />
    <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} />
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} /> */}

    <Redirect to={Routes.NotFound.path} />
  </Switch>
  {/* </Router> */}
  </AuthProvider>
);
