import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faArrowDown, faArrowUp, faEdit, faEllipsisH, faExternalLinkAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup, Badge } from '@themesberg/react-bootstrap';
import { useHistory ,Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'; 
import jwt_decode from 'jwt-decode';
import { Routes } from "../routes";
import { pageVisits, pageTraffic, pageRanking } from "../data/tables";
import Swal from 'sweetalert';
import { useAuth } from '../pages/AuthContext';
import axios from 'axios';
import DataTable from 'react-data-table-component';


export const ProspectionsTable = ({userId, userData}) => {

  const [prospecting, setProspecting] = useState([])
  const [deletionVisible, setDeletionVisible] = useState(false)
  const history = useHistory()
  const [myrole, setMyrole] = useState('');
  const userCon =  JSON.parse(localStorage.getItem("user"))

  const totalProspecting = 1// prospecting.length;

  let modalClass = 'modal';
  if (deletionVisible) {
    modalClass += ' show d-block';
  }
  const { token } = useAuth();

  const itemsPerPage = 10; // Nombre d'éléments par page
  const totalItems = prospecting== undefined ?1:prospecting.length; // Nombre total d'éléments
  const [currentPage, setCurrentPage] = useState(1); // État pour suivre la page actuelle

  const startIndex =(currentPage - 1) * itemsPerPage;
  const endIndex =startIndex + itemsPerPage;

  // Liste à afficher sur la page actuelle
  const currentPageItems = prospecting == undefined ? 1 :  prospecting.slice(startIndex, endIndex);

// Fonction pour changer de page
const handlePageChange = (page) => {
  setCurrentPage(page);
};

// Pagination
const totalPages = Math.ceil(totalItems / itemsPerPage);
const paginationItems = [];
for (let i = 1; i <= totalPages; i++) {

 paginationItems.push(
  <Pagination.Item active = {i==currentPage?true:false}  key={i} onClick={() => handlePageChange(i)}>{i}</Pagination.Item>
    //  <button key={i} onClick={() => handlePageChange(i)}>{i}</button>
 );
}

const getForms = async () => {
  try {
    const res = await axios.post('/api/getforms', { userId }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    // console.log('resultats forms '+JSON.stringify(res) )
    setProspecting(res.data.prospectings)
  } catch (err) {
    // console.log('Failed to create data');
    console.error(err);
    // history.push("/sign-in");
  }
};

const columns = [
  {
    name: 'PROJET',
    selector: row => <Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/viewprospection/${row._id}`}} > {row.name}</Card.Link>,
    width: '200px'
  },
  {
    name: 'Données',
    selector: row => row.nbForms,
    cell: row => <Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/viewprospection/${row._id}`}} > {row.nbForms}</Card.Link>,
    sortable: true,
  },
  {
    name: 'Nombres des champs',
    selector: row => row.nbContent,
    cell: row => <Card.Link as={Link} className="text-primary fw-bold" to={{ pathname: `/viewprospection/${row._id}`}} > {row.nbContent}</Card.Link>,
    sortable: true,
  },

  
  {
    name: 'Date de création',
    selector: row =><Card.Link as={Link} className="text-primary" to={{ pathname: `/viewprospection/${row._id}`}} > {row.createdAt}</Card.Link> ,
    sortable: true,
  },
  {
    name: 'Date dernière modification',
    selector: row =><Card.Link as={Link} className="text-primary" to={{ pathname: `/viewprospection/${row._id}`}} > {row.modifiedAt}</Card.Link> ,
    sortable: true,
  },
  
  {
    name: 'Créateur',
    selector: row =><Card.Link as={Link} className="text-primary" to={{ pathname: `/viewprospection/${row._id}`}} > {row.firstName}</Card.Link> , 
    sortable: true,
  },

  {
    name: 'Status',
    selector: row => <Card.Link as={Link} to={`/viewprospection/${row._id}`} className="fw-normal">
            
    <Badge bg={row.state === "draft" ? "danger" : row.state === "deployed" ? "success" : "primary"} className="badge-lg">{row.state == 'draft' ? 'Brouillon' : row.state == 'deployed' ? 'Déployé' : ''}</Badge>
    </Card.Link> ,
    sortable: true,
  },
  {
    name: 'ACTION',
    selector: row =>  <>
                          <Link to={`/viewprospection/${row._id}`}>
                                <FontAwesomeIcon  icon={faEye} className="me-2" />
                          </Link> 
                          {
                              (myrole != undefined && ['RESPONSABLE','ADMIN'].includes(myrole)) ? (
                                  <>
                                        <Link to={{pathname:`/modifyforms/${row._id}`, state:{formId: row._id, formName:row.name, options:{}}}}>
                                          <FontAwesomeIcon  icon={faEdit} className="me-2" />
                                        </Link> 
                                        <Link onClick={() => processDeletion([row._id,userCon._id])}>
                                          <FontAwesomeIcon  icon={faTrashAlt} className="text-danger me-2" />
                                        </Link> 
                                  </> 
                                ) :
                                (<></>)
                            } 
                      </>,
    sortable: true,
  }
];


useEffect(() => {
    
  fetch(`/api/getUser/${userCon._id}`).then(
  response => response.json()
).then(
  response => {
    setMyrole(response[0].role)
  }
)}, [userCon])

    useEffect( ()=> {
      // console.log('resultats forms '+userId)
      getForms()
    }, [userId])

    const processDeletion = (item) => {
      swal({
        title: "Confirmation",
        text: "Voulez-vous supprimer définitivement ce formulaire ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        if (result) {
          deleteItems(item)
        } 
      });
    }

    const deleteItems = (item) => {
      fetch(`/api/deleteItem/${item[0]}/${item[1]}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(
        response => response.json()
          
      ).then(
          
          data => {
            
            // setDeletionVisible(false)
            if(data['status'] == 'success') {
              // console.log("remove item ", item[0]) 
              
              setProspecting(current =>
                current.filter(prospecting => {
                  // 👇️ remove object that has id equal to item[0]
                  return prospecting._id !== item[0];
                }),
              );

              swal({
                title: "Succès",
                text: data.message,
                icon: "success",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });

              
            } else if(data.status === "session_expired") {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('user')
              swal({
                title: "Session expirée",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
              history.push("/sign-in");

           } else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
          }
      )
    }
    
    const customStyles = {
      headCells: {
        style: {
          fontWeight: 'bold',
          backgroundColor: '#1f2334', 
          color : 'white'// You can set your desired background color here
        },
      },
    };
  
  
  
    return (
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
        <DataTable
                columns={columns}
                data={prospecting}
                  pagination
                  customStyles={customStyles}
                        // paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        // subHeaderComponent={subHeaderComponent}
                        // actions={actionsMemo}
                />
                
         
        </Card.Body>

        <div>
            <Toaster   
              position="top-right"
              reverseOrder={false} />
          </div>

        { deletionVisible &&
          <div className={modalClass} role="dialog">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                  <h4>Suppression</h4>
                  Voulez-vous supprimer {deletionVisible[1]} ?

                <div className="modal-footer">
                  <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={() => deleteItems(deletionVisible)}>Supprimer</button>
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => setDeletionVisible(false)}>Fermer</button>
                </div>
              </div>
            </div>
          </div>
        }
      </Card>


    );
  };
  