import React, {useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import FormBuilder, { Registry } from '../components/react-form-builder/index';
import DemoBar from './DemoBar';
import * as variables from '../../variables';
import {  Breadcrumb, Button } from '@themesberg/react-bootstrap';
import { useHistory, useLocation,useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import AuthContext from "../context/AuthProvider";
import { Link } from 'react-router-dom';


// Add our stylesheets for the demo.
require('../scss/react-form-builder/application.scss');


const url = '/api/prospectings';
const saveUrl = '/api/prospecting';

export default () => {
  const [ userId, setUserId ] = useState();//useContext(AuthContext);
  const history = useHistory()
  const [prospecting, setProspecting] = useState([])
  const [formsInfo, setFormsInfo] = useState({})
  const token = localStorage.getItem("accessToken")
  const { projectId } = useParams();
//   Registry.register('MyInput', MyInput);
// Registry.register('TestComponent', TestComponent);
const [forms, setForms] = useState({})
const userCon =  JSON.parse(localStorage.getItem("user"))
console.log('m prject'+projectId)
const items = [{
    key: 'Header',
  },{
    key: 'Label',
  },{
    key: 'Paragraph',
  },{
    key: 'TextInput',
  },{
    key: 'EmailInput',
  },{
    key: 'PhoneNumber',
  },{
    key: 'NumberInput',
  },{
    key: 'Dropdown',
  }, {
    key: 'TextArea',
  }, {
    key: 'RadioButtons',
  }, {
    key: 'Checkboxes',
  }, {
    key: 'Image',
  },
  {
    key: 'DatePicker',
  },
  {
    key: 'Tags',
  },
  {
    key: 'HyperLink',
  },
  {
    key: 'Camera',
  },
  { 
    key: 'FileUpload'
  },
  {
    key: 'Signature',
  },
  {
    key: 'Rating',
  },
  {
    key: 'Range',
  },
  {
    key: 'FieldSet',
  },
  {
    key: 'TwoColumnRow',
  }
];


  useEffect(() => {
    const checkUserConnected = () => {
      try {
        
        const decodedToken = jwt_decode(token);
       
        setUserId(decodedToken.id)
        
      }catch(err) {
        console.log(err)
      }
    }
    checkUserConnected();
  }, []);

  const location  = useLocation();
  const {  formId,formName,  options } = location.state || {};
  // const formId = params.formId;
  const forms_info = {
    name: forms.name,
    options: options ,
    userId:userCon._id
  }
  
  useEffect(() => {
    const userCon =  JSON.parse(localStorage.getItem("user"))
  
      fetch("/api/getFormsName/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({formId : projectId,userId: userCon._id }),
      }).then(
          response => response.json()
      ).then(
          data => {
           
            setForms({
              idProject : data['forms'][0]._id,
              userId:userCon._id,
              name : data['forms'][0].name,
              content : data['forms'][0].content
            })
  
            console.log('data forms' + JSON.stringify(data) )
          }
      )}, [projectId])

  useEffect( ()=> {
    let mounted = true;
    async function fetchData(){
      fetch(`/api/getform/${formId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(
        response => response.json()
        
      ).then(
          data => {
            if(mounted) {
              setProspecting(data["prospecting"][0]) 
              
              setFormsInfo({
                ...formsInfo,
                ...data["prospecting"][0]
              });
        
            }
          }
      )
    };
    


    fetchData();
    return ()=> {mounted = false}

}, [])


console.log('my formulaires'+ JSON.stringify(forms) )  
  return (
    <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/forms-project' }}>Projet</Breadcrumb.Item>
            <Breadcrumb.Item active>{forms.name || ''}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Modifier votre formulaire</h4>
         
      
          {/* <p className="mb-0">Gérer votre prospection selon votre besoin.</p> */}
        </div>
        
      </div>
    {
      forms.content ? (
        <>
          <DemoBar variables={variables} forms_info={{...forms, ...{userId}}} />

          <FormBuilder.ReactFormBuilder
            variables={variables}
            saveUrl={saveUrl}
            locale='fr'
            data=  {forms.content ?JSON.parse(forms.content) :[]}
            saveAlways={false}
            forms_info={forms} //custom field
            toolbarItems={items}

          />
        </>
      ) : (
        <p>Chargement...</p>
      )
    }

    </>
  );
};