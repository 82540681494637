
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup } from '@themesberg/react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import { Routes } from "../routes";


export default () => {
  const history = useHistory()
  const routeParams  = useParams();
  const sessionID = routeParams.sessionID
  const userId = routeParams.userId
  const token = routeParams.token

  const { googleLogin } = useAuth();

  useEffect(() => {
    const checkOnlineStatus = () => {
      // check google login session
      googleLogin(userId,sessionID,token);
        
      }
      checkOnlineStatus();
  },[])

  return (
    <main>
      <p>vérification de votre session {userId}</p>
    </main>
  );
};
