import React,{useState, useContext, useEffect, useMemo} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister,faChartLine, faCloudUploadAlt,faUsers,faTable , faDatabase,faUser,faCity , faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
// import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import {Form ,  Breadcrumb,Button,Col, Row , Nav, Card, Image, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';


import jwt_decode from 'jwt-decode';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
import Chart from 'react-apexcharts';


import {  faComment } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,AreaChart,Area } from 'recharts';
import DataTable from 'react-data-table-component';
import { useAuth } from '../AuthContext';
import MapAdvanced from '../../components/MapAdvanced';
import { CounterWidget,  FormsValueWidget } from "../../components/Widgets";

export default () => {
  const history = useHistory()
  const [ userId, setUserId ] = useState();// useContext(AuthContext);
  const [ formsData, setFormsData ] = useState(0)
  const [ allFormsData, setAllFormsData ] = useState([])
  const [selectedForm,setSelectedForm] = useState('')
  // const [lead,setLead] =useState('')
  const [listLead,setListlead] =useState([])

  const [ formsLocation, setFormsLocation ] = useState([])

  const today = new Date().toISOString().split('T')[0];

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  

  const [ usersOnline, setUsersOnline ] = useState([])  
  const userCon  =  JSON.parse(localStorage.getItem("user"))
  const [ formsId, setFormsId] = useState("")
  const [ leadId, setLeadId] = useState("")
  const [ selectedUser, setSelectedUser] = useState("")

  const [ colTr, setcolTr] = useState("grey")
  const [ nbTeam, setNbTeam] = useState(0)
  const [ nbForm, setNbForm] = useState(0)
  const [dailyOptions, setDailyOptions] = useState([]);
  const [dailySeries, setDailySeries] = useState([]);
  const [formsLead, setFormsLead] = useState([])
  const [activeButton, setActiveButton] = useState('daily');
  const { token } = useAuth();
  const [user,setUser] =useState([])

   
  const [listLeadName, setListLeadName] = useState([]);
  const [listLeadNb, setListLeadNb] = useState([]);


  const [listFormsName, setListFormsName] = useState([]);
  const [listFormsNb, setListFormsNb] = useState([]);

  const [listForms,setListForms] =useState([])

  const fetchListForms= () => {
    fetch(`/api/getListForms/${userCon._id}`).then(
    response => response.json()
).then(
    data => setListForms(data["forms"])
)
};


  function fetchNbteam(){

    fetch(`/api/getNbteam/${userCon._id}`).then(
      response => response.json()
    ).then(
        data => {
          setNbTeam(data['nb'])
        })
  }

  
  function fetchNbForms(){
    fetch(`/api/getNbforms/${userCon._id}`).then(
      response => response.json()
    ).then(
        data => {
          setNbForm(data['nb'])
        })
   
  }

  function fetchAllFormsData(leadId,sDate,eDate){
    fetch(`/api/getAllFormsResponse`,{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({userId :userCon._id,leadId : leadId ,dateStart : sDate, datEnd : eDate}),
    }).then(
      response => response.json()
    ).then(
      data => {
       
        if(data.status === "success") {
          // console.log('forms data'+JSON.stringify(data) )
          setAllFormsData(data['forms'])

          const formName = data['forms'].map(item => item.formName);
          const nbForm  = data['forms'].map(item => item.nbForms);

          setListFormsName(formName);
          setListFormsNb(nbForm);
          
        }else if(data.status === "session_expired") {
         
          history.push("/sign-in");
        }else {
          swal({
            title: "Erreur",
            text: data.message,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((result) => {
            console.log(result)
          });
        }
        
      }
    )
  }


  async function fetchDaily(forms, user, leadId, activeButton) {
    console.log('my fetch daily');
    const res = await axios.post('/api/getDailyResponse', {
      idConnected: userCon._id,
      idForms: forms,
      leadId: leadId,
      userSelected: user,
      period: activeButton
    }, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    const newDailyOptions = res.data.forms.map(item => item.daty);
    const newDailySeries = res.data.forms.map(item => item.nbForms);

    if (JSON.stringify(newDailyOptions) !== JSON.stringify(dailyOptions)) {
      setDailyOptions(newDailyOptions);
    }

    if (JSON.stringify(newDailySeries) !== JSON.stringify(dailySeries)) {
      setDailySeries(newDailySeries);
    }
  }

function fetchFormsLocation(selectedForm,selectedUser,leadId,sDate,eDate) {
  fetch('/api/getFormsLocationFilter', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({idConnected :userCon._id,idLead :leadId ,idForms: selectedForm,idUser: selectedUser,dateStart : sDate, datEnd : eDate}),
}).then(
response => response.json()
).then(
data => {
  if(data.status === "success") {   
   
    setFormsLocation(data)
  }else if(data.status === "session_expired") {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('user')
    swal({
      title: "Session expirée",
      text: data.message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((result) => {
      console.log(result)
    });
    history.push("/sign-in");
  }else {
    swal({
      title: "Erreur",
      text: data.message,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((result) => {
      console.log(result)
    });
}
}
)
}

const fetchLead = () => {
  fetch(`/api/getLead/${userCon._id}`).then(
      response => response.json()
  ).then(
      data =>   setListlead(data["lead"])
  )
}

function fetchUserOnline(leadId,formsId,sDate,eDate){
  fetch('/api/getOnlineUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({userId :userCon._id,leadId : leadId ,idForms: formsId,dateStart : sDate, datEnd : eDate}),
     }).then(
      response => response.json()
    ).then(
        data => {
          
          if(data.status === "success") { 
              const onUser = ( data['users'].length == 0 ) ? ([]) : (
                data['users'].map((user) => ({
                  _id : user._id,
                  "name":  user.firstName,
                  nbForms : user.nbForms,
                  connected : user.connected,
                  maxDate : user.maxDate
              })) 
              )
            setUsersOnline(onUser)
          }else if(data.status === "session_expired") {
            console.log('users online'+JSON.stringify(data) )
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user')
            swal({
              title: "Session expiréeberbfed",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
            history.push("/sign-in");
          }else {
            swal({
              title: "Erreur",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
          }
          
        }
    )
  }

function fetchLeadStat(leadId,forms,sDate,eDate){
  fetch('/api/getFormsLead', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({userId :userCon._id,leadId:leadId ,idForms: forms,dateStart : sDate, datEnd : eDate}),
     }).then(
      response => response.json()
    ).then(
      data => {
        setFormsLead(data['forms'])
        const nameLead = data.forms.map(item => item.leadFirstName);
        const formLead = data.forms.map(item => item.nbForms);
        
        setListLeadName(nameLead);
        setListLeadNb(formLead);

        console.log('resulta' + JSON.stringify(nameLead))
        console.log('resulta' + JSON.stringify(formLead))
      })
}

const fetchUsers = (leadId) => {
  fetch('/api/getUsersLead', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({userId :userCon._id,leadId : leadId}),
     }).then(
      response => response.json()
    ).then(data => setUser(data["users"]))

}
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  useEffect(() => {
    fetchFormsLocation(selectedForm,selectedUser,leadId,startDate,endDate);
    fetchAllFormsData(leadId,startDate,endDate);
    fetchUserOnline(leadId,selectedForm,startDate,endDate)
    fetchLeadStat(leadId,selectedForm,startDate,endDate)
  }, [selectedForm, selectedUser, leadId,startDate,endDate]);


  useEffect(() => {
    fetchDaily(selectedForm, selectedUser, leadId, activeButton);
    
  }, [activeButton, selectedForm, selectedUser, leadId]);

  useEffect(() => {
    fetchListForms()
    fetchNbteam();
    fetchNbForms()
    fetchLead()
  }, []);



  const handleEndDate = (e) => {
    setEndDate(e.target.value);
  };

  const handleStartDate = (e) => {
    console.log('start date');
    setStartDate(e.target.value);
  };

  const handleForms = (e) =>{
    setSelectedForm(e.target.value)
}

const handleUser = (e) =>{
  // fetchFormsLocation(selectedForm,e.target.value,lead,startDate,endDate )
  setSelectedUser(e.target.value)
}

const handleLead = (e) =>{
  // console.log('my lead '+ e.target.value)
  setSelectedUser('')
  fetchUsers(e.target.value)
  setLeadId(e.target.value)
}

  const Achart = ({ series, options }) => {

   
    
    console.log('Rendering Achart');
    const chartOptions = {
      chart: {
        id: "area-chart",
        type: "area",
        toolbar: {
          show: false
        }
      },
      xaxis: {
        categories: options,
      },
      fill: {
        colors: ["#43d4ef"],
      },
      dataLabels: {
        enabled: true,
      },
      stroke: {
        curve: "smooth",
      },
    };

    const chartSeries = [
      {
        name: "Nombres donnees soumises",
        data: series,
      },
    ];

    return (
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="area"
        height="250"
      />
    );
  };
  
  const Projectchart = ({ series, options }) => {
    const handleDataPointSelection = (event, chartContext, { dataPointIndex }) => {
      console.log('Clicked value:', options[dataPointIndex]);

      if (series && series.length > 0 && series[0].data) {
        const value = series[0].data[dataPointIndex]; // Get the value of the clicked bar
        console.log('Clicked value:', dataPointIndex);
        // Additional logic can be added here
      } else {
        console.error('Series data not available');
      }
    };


    const chartOptions = {
      chart: {
        id: "area-chart",
        type: "bar",
        events: {
          dataPointSelection: handleDataPointSelection,
        },
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      xaxis: {
        categories: options,
      },
      yaxis: {
        title: {
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#43d4ef'], 
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
      fill: {
        colors: ["#212234"],
      }
    
    };
  
    const chartSeries = [
      {
        name: "Nombres données soumises",
        data: series,
      },
    ];
  
    return  <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height="250"
              />  
  };

  const Leadchart = ({ series, options }) => {
     
    const chartOptions = {
      chart: {
        id: "area-chart",
        type: "bar",
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
        }
      },
      xaxis: {
        categories: options,
      },
      yaxis: {
        title: {
        },
      },
      dataLabels: {
        enabled: true,
        style: {
          colors: ['#43d4ef'], 
          fontSize: '14px',
          fontWeight: 'bold',
        },
      },
      fill: {
        colors: ["#212234"],
      }
    
    };
  
    const chartSeries = [
      {
        name: "Nombres données soumises",
        data: series,
      },
    ];
  
    return  <Chart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height="250"
              />  
  };


  const chartContent = useMemo(() => {
    return <Achart series={dailySeries} options={dailyOptions} />;
  }, [dailySeries, dailyOptions]);

  const chartTopProject = useMemo(() => {
    return <Projectchart series={listFormsNb} options={listFormsName} />;
  }, [listFormsNb, listFormsName]);
  
  const chartLead = useMemo(() => {
    return <Leadchart series={listLeadNb} options={listLeadName} />;
  }, [listLeadNb, listLeadName]);
  
  const columnsUser = [
    {
      name: 'NOM',
      selector:row => row.name,
      sortable: true,
      // width: '60px'
    },
    {
      name: 'DONNÉES',
      selector:row =>row.nbForms,
      sortable: true,
      // width: '60px'
    },
    {
      name: 'Date derniere sous.',
      // selector:row =>row.maxDate,
      selector: row => row.maxDate,
      cell: row =>  row.maxDate == '2024-01-01' ?'Pas encore connecté' : row.maxDate,
  
      sortable: true,
      // width: '60px'
    },
]
const customStyles = {
  rows: {
    style: {
      minHeight: '30px', // Adjust the height of each row
    },
  },
  headCells: {
    style: {
      // padding: '5px', // Reduce padding in header cells
      fontSize: '14px',
      height :'30',
      backgroundColor :'#1f2334',
      color :'white'
       // Adjust font size in header cells
    },
  },
  headRow: {
    style: {
      minHeight: '30px', // Set minHeight to 0 or desired value
      // marginBottom: '0px', // Remove margin if needed
    },
  },
  cells: {
    style: {
      padding: '5px', // Reduce padding in body cells
      fontSize: '14px', // Adjust font size in body cells
    },
  },
};

  // console.log('forms location'+ JSON.stringify(formsLocation.forms.length) )
  return (
    <>
  <Row>
    {/* Inputs Section */}
    <Form className="mt-4 p-3 shadow-lg bg-light rounded">
      <Row>
        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group> 
            <Form.Label><i className="fas fa-user-plus"></i> Responsable</Form.Label>
            <Form.Select  onChange={handleLead} className="shadow-sm dashboard-select">
              <option value="">Tous les responsables</option> 
              {listLead &&
                              listLead.map(l => <option value={l._id}>{l.firstName}</option> )
                          }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}>
          <Form.Group>
            <Form.Label><i className="fas fa-project-diagram"></i> Nom du projet</Form.Label>
            <Form.Select onChange={handleForms} className="shadow-sm dashboard-select">
              <option value="">Tous les projets</option> 
              {listForms &&
                                listForms.map(l => <option value={l._id}>{l.name}</option> )
                            }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-user"></i> Nom d'utilisateur</Form.Label>
            <Form.Select onChange={handleUser}   className="shadow-sm dashboard-select">
              <option value="">Tous les utilisateurs</option> 
                  {user &&
                    user.map(l => <option value={l._id}>{l.firstName}</option> )
                  }
            </Form.Select>
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-calendar-alt"></i> Date de début :</Form.Label>
            <Form.Control 
              type="date" 
              value={startDate} 
              onChange={handleStartDate} 
              className="shadow-sm dashboard-select"
            />
          </Form.Group>
        </Col>

        <Col className="mb-3" xs={12} sm={6} xl={2}> 
          <Form.Group>
            <Form.Label><i className="fas fa-calendar-alt"></i> Date de fin :</Form.Label>
            <Form.Control 
              type="date" 
              value={endDate} 
              onChange={handleEndDate} 
              className="shadow-sm dashboard-select"
            />
          </Form.Group>
        </Col>
      </Row>
    </Form>
  </Row>

  {/* Main Content: Left (Boxes and Graphs) and Right (Map) */}
  <Row className="mt-4">
    {/* Left side: Boxes and Graphs */}
    <Col xs={12} xl={8}>
      {/* 4 Counter Boxes */}
      <Row className="mb-4">
        <Col xs={12} sm={6} xl={3} className="mb-3">
          <CounterWidget
            category="Données soumises"
            title={formsLocation.forms == undefined ? 0 : formsLocation.forms.length}
            period="28 Apr"
            icon={faDatabase}
            iconColor="shape-tertiary"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-3">
          <CounterWidget
            category="Utilisateurs actifs"
            title={usersOnline == undefined ? 0 : usersOnline.filter((row) => row.nbForms > 0).length}
            period="28 Apr"
            percentage={usersOnline == undefined ? 0 : usersOnline.length}
            icon={faUser}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-3">
          <CounterWidget
            category="Equipes"
            title={nbTeam}
            period="28 Apr"
            percentage={nbTeam}
            icon={faUsers}
            iconColor="shape-secondary"
          />
        </Col>

        <Col xs={12} sm={6} xl={3} className="mb-3">
          <CounterWidget
            category="Projets"
            title={nbForm == undefined ? 0 : nbForm}
            period="28 Apr"
            percentage={18.2}
            icon={faTable}
            iconColor="shape-secondary"
          />
        </Col>
      </Row>

      {/* Graphs Section */}
      <Row>
      
        <Col xs={12} sm={5} className="mb-4">
            <Card border="light" className="shadow-sm">
                <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                  <h5 className="mb-0">Top 10 projet en cours</h5>
                  {/* <Button variant="secondary" size="sm">Voir plus</Button>   */}
                </Card.Header>
              {/* <Chart options={options1} series={series} height={250}/> */}
              {chartTopProject}

            </Card>
        </Col>
        <Col xs={12} sm={7} className="mb-4">
        <Card border="light" className="shadow-sm">
      {/* Card.Header avec flexbox et espacement ajusté */}
      <Card.Header className="border-bottom border-light d-flex justify-content-between align-items-center">
        {/* Titre aligné à gauche */}
        <h5 className="mb-0">Evolution données collectées</h5>

        {/* ButtonGroup aligné à la droite du header */}
        <div className="ml-auto d-flex justify-content-end" style={{ marginRight: '0px' }}>
          <ButtonGroup aria-label="Basic example">
            <Button
              size="sm"
              onClick={() => handleButtonClick('daily')}
              variant={activeButton === 'daily' ? 'primary' : 'secondary'}
            >
              Jours
            </Button>
            <Button
              size="sm"
              onClick={() => handleButtonClick('weekly')}
              variant={activeButton === 'weekly' ? 'primary' : 'secondary'}
            >
              Hebdo
            </Button>
            <Button
              size="sm"
              onClick={() => handleButtonClick('monthly')}
              variant={activeButton === 'monthly' ? 'primary' : 'secondary'}
            >
              Mois
            </Button>
          </ButtonGroup>
        </div>
      </Card.Header>

      {/* Chart content */}
      {chartContent}
    </Card>
        </Col>
      </Row>

      <Row>
      <Col xs={12} sm={5} className="mb-4">
      <Card border="light" className="shadow-sm">
                <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                  <h5 className="mb-0">Recap par responsables</h5>
                  {/* <Button variant="secondary" size="sm">Voir plus</Button>   */}
                </Card.Header>
              {/* <Chart options={options1} series={series} height={250}/> */}
              {chartLead}

            </Card>
        </Col>

           
        <Col xs={12} sm={7} className="mb-4">
           
            <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                      <h5 className="mb-0">Recap par Utilisateurs</h5>
                </Card.Header>
            <Card.Body>
               
                <DataTable
                        columns={columnsUser}
                        data={usersOnline}
                        pagination
                        // onRowClicked={changeUser}
                        // conditionalRowStyles={conditionalRowStylesUser}
                        pointerOnHover 
                        customStyles={customStyles}
                />
            </Card.Body>
            </Card>
            
        </Col>
      </Row>
    </Col>

    {/* Right side: Map */}
    <Col xs={12} xl={4}>
    <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                      <h5 className="mb-0">Afficher Cartes par</h5>
                      <Form.Group>
                        {/* <Form.Label><i className="fas fa-users"></i>Afficher</Form.Label> */}
                        <Form.Select className="shadow-sm dashboard-select">
                          <option value="">Total données soumises</option> 
                          {/* <option value="">Status des utilisateurs</option> */}
                        </Form.Select>
                      </Form.Group>
                </Card.Header>
            <Card.Body>
                {formsLocation.length === 0 ? (
                  <span>Aucune donnée</span>
                ) : (
                  <MapAdvanced id="map" marker={formsLocation}  width="100%" height="1000px" />
                  )}
            </Card.Body>
            </Card>
      </Col>
    </Row>
    </>
  );
};
