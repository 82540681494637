import React, {useState,useEffect} from "react";
import {Modal ,Form ,Button,Col, Row,Toast  } from '@themesberg/react-bootstrap';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../pages/AuthContext';

export const CreateTeam = ({ show, handleClose,onCreateTeam }) => {
  const history = useHistory()
  const { token } = useAuth();
  const [formData, setFormData] = useState({
    teamName: '',
    desc: ''
  });
  const [user, setUser] = useState([])


  useEffect(() => {
    let userCon =  localStorage.getItem("user")

    if(userCon !== null) {
  
      userCon =  JSON.parse(userCon)
      fetch(`/api/getAllUsers/${userCon._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(response => response.json())
        .then(
        data => {
          
          if(data.status === "success") {
            // console.log('all forms '+data)
            setUser(data["users"])
          }else if(data.status === "session_expired") {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user')
            swal({
              title: "Session expirée",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
            history.push("/sign-in");
          }else {
            swal({
              title: "Erreur",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
          }
        }
      );
    }else {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      swal({
        title: "Session expirée",
        text: "Votre session a expirée.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((result) => {
        console.log(result)
      });
      history.push("/sign-in");
    }
    
  }, []);

  const [userList, setUserList] = useState([])

  const FormatUser = user.map((option,index) => ({
    value: index,
    label: option.firstName,
  }));

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }

    // const handleChangeUser = (selectedOptions) => {
    //   let selectedValues;

    //   if(selectedOptions === null ){
    //     selectedValues = []
    //   }else{
    //      selectedValues = selectedOptions.map((option) => user[option.value]);
    //   }
    //   setUserList(selectedValues)
    // };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const { teamName, desc} = { ...formData};
      onCreateTeam({name: teamName, desc});
      handleClose();
    };



    return( 
      <>
        <Modal as={Modal.Dialog} centered show={show} onHide={handleClose} animation={false}  backdrop="static" > 
        <Modal.Header>
          <Modal.Title className="h6">Créer une Equipe</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleClose} />
        </Modal.Header>
        <Modal.Body>
          <Form >
              <Row>
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control  name="teamName"  onChange={handleInputChange}  required type="text"/>
                  </Form.Group>
              </Row>
              
              <Row>
                  <Form.Group>
                    <Form.Label>Descritpion</Form.Label>
                    <Form.Control  name="desc"  onChange={handleInputChange}  required type="text"/>

                  {/* <Select
                    isMulti
                    options={FormatUser}
                    onChange={handleChangeUser}
                    placeholder="Sélectionnez plusieurs options..."
                  /> */}
                  </Form.Group>
              </Row>
          </Form>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="link" className="text-gray ms-auto" onClick={handleClose}>
              Annuler
            </Button>
            <Button variant="secondary" type="submit" onClick={handleSubmit}>
              Créer
            </Button>     
          </Modal.Footer>
      </Modal>
      </>
    )   
}

// export default Modaly