
import { faBrain, faMap, faAlignJustify,faCalendarAlt, faFileAlt, faImages, faMapMarkedAlt, faMobileAlt, faPenFancy } from "@fortawesome/free-solid-svg-icons";
import { faWpforms, faGulp, faHtml5, faReact, faSass } from "@fortawesome/free-brands-svg-icons";

export default [
    {
        "id": 0,
        "title": "Formulaire Dynamique",
        "icon": faWpforms,
        "description": "Créez et déployez vous-même votre formulaire sans codage."
    },
    {
        "id": 1,
        "title": "Suivi d'équipe sur terrain",
        "icon": faMap,
        "description": "Obtenez une visibilité sur vos équipes sur terrain même assis sur votre bureau"
    },
    {
        "id": 2,
        "title": "Application mobile puissante",
        "icon": faMobileAlt,
        "description": "Simplifiez la tâche de vos équipes sur le terrain grâce à notre application mobile."
    },
    {
        "id": 3,
        "title": "Intelligence Artificielle",
        "icon": faBrain,
        "description": "Optimisez l'efficacité de votre processus avec nos modèles de reconnaissance d'images."
    }
];