
import React, {useState} from 'react';
import { Row, Col, Card, Container } from '@themesberg/react-bootstrap';
import { HashLink } from 'react-router-hash-link';
import FieldConnectLogo from "../assets/img/logo.png";
import { Link } from 'react-router-dom';
import {  Image, Button, Navbar, Nav, Spinner, Badge } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import {  faTemperatureHigh } from "@fortawesome/free-solid-svg-icons";
import { toast, Toaster } from 'react-hot-toast'; 


export default () => {
    const [emailNewsLetter, setEmailNewsLetter] = useState('');
    const [inLoading, setInLoading] = useState(false)
  
    const handleNewsLetter = (e) => {
      e.preventDefault()
      let reg =   /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  
  
      if(emailNewsLetter != "" && reg.test(emailNewsLetter) ) {
        setInLoading(faTemperatureHigh)
        fetch(`/api/newsletter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({emailNewsLetter:emailNewsLetter})}).then(
          response => response.json()
        ).then(
          response => {
              setInLoading(false)
             if(response.status == "success") {
              setEmailNewsLetter("")
              toast.success(response.message, {
                style: {
                  border: '1px solid #713200',
                  padding: '16px',
                }});
             } else {
              toast.error(response.message, {
                style: {
                  border: '1px solid #713200',
                  padding: '16px',
                }});
             }
              
          }
        )
      } else {
        toast.error('Veuillez saisir une adresse email valide.', {
          style: {
            border: '1px solid #713200',
            padding: '16px',
          }});
      }
    }

    return (<>
     <Navbar variant="dark" expand="lg" bg="dark" className="navbar-transparent navbar-theme-primary sticky-top">
        <Container className="position-relative justify-content-between px-3">
          <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 d-flex align-items-center">
            <Image style={{height:50}} src={FieldConnectLogo} />
            {/* <span className="ms-2 brand-text d-none d-md-inline">FieldConnect</span> */}
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            <Navbar.Collapse id="navbar-default-primary">
              <Nav className="navbar-nav-hover align-items-lg-center">
                <Nav.Link as={HashLink} to="/#presentation" target="_blank">Présentation</Nav.Link>
                <Nav.Link as={HashLink} to="/#features" target="_blank">Fonctionnalités</Nav.Link>
                <Nav.Link as={HashLink} to="/#pricing" target="_blank" className="d-sm-none d-xl-inline">Abonnement </Nav.Link>
                <Nav.Link as={HashLink} to="/#contact" target="_blank">Nous contacter</Nav.Link>
                {/* <Nav.Link as={HashLink} to="#download">Tarification</Nav.Link> */}
              </Nav>
            </Navbar.Collapse>
            <Button style={{marginRight: 10, marginLeft: 50}} as={Link} to={Routes.Signin.path} variant="outline-white" className="ms-3">Espace client</Button>
            <Button variant="secondary" as={Link} to={Routes.Signup.path} className="text-dark me-3">
            Essayer gratuitement 
            {/* <FontAwesomeIcon icon={faExternalLinkAlt} className="d-none d-sm-inline ms-1" /> */}
                </Button>
            
          </div>
        </Container>
      </Navbar>
      <Container className="px-0">
        
        <Row>
        <Col xs={12} className="p-3">
            <Card>
            <Card.Body>
                <article>
                <h1 className="h2" id="overview">Termes et conditions d'utilisation de la plateforme FieldConnect
                </h1>
                <br />
                {/* <p className="fs-5 fw-light">Download files for Volt React Dashboard</p> */}

                <p>Le présent document définit les termes et conditions de l’utilisation de la plateforme FieldConnect. Cette application Web permet à leurs utilisateurs de faciliter le processus de collecte d’informations commerciales</p>
                

                <h4 id="definition">Article 1 : DEFINITION</h4>
                <ul className="docs-list">
                    <li>« FieldConnect » est une plateforme de collection de données, qui offre la possibilité de gérer les formulaires dynamiquement, ainsi qu'une visibilité des équipes sur terrain.  </li>
                    <li>« Utilisateur » désigne toutes les entreprises utilisant l’Application Web après s’être identifiées.</li>
                    <li>« Parties » désigne ensemble l’Utilisateur et FieldConnect.</li>
                    <li>« CGU » : désigne les présentes conditions générales d'utilisation de FieldConnect ;</li>
                </ul>

                <h4 id="object">Article 2 : OBJECT</h4>
                    <p>Le présent document définit les conditions et modalités d’utilisation de la plateforme.  </p>
                    <p>Toute personne qui accède à et/ou utilise FieldConnect s’engage à respecter, sans réserve, le présent document.</p>
                    <p>L’autorisation préalable de la société FieldConnect est exigée pour qu’une personne puisse avoir accès à cette plateforme. Cette dernière devant, par ailleurs, respecter la règlementation en vigueur et ainsi les présents termes et conditions.</p>
                
                <h4 id="identification">Article 3 : IDENTIFICATION</h4>
                <p>Pour son identification, le futur utilisateur fournit les informations utiles et nécessaires en s'engageant à fournir des informations vraies, exactes, à jour et complètes nécessaires à la création du compte d’utilisateur et à ne pas créer une fausse identité de nature à induire FieldConnect ou les tiers en erreur et à ne pas usurper l'identité d'une autre personne morale ou physique. 
                    L’utilisateur autorise ainsi FieldConnect à utiliser les informations fournies ainsi que les données personnelles, dans la mesure de permettre à la plateforme de fonctionner comme son objet l’indique.
                </p>
                <p>Pour pouvoir terminer l’identification de l’Utilisateur, celui-ci doit cocher la case « J'ai lu et j'accepte les termes et conditions d’utilisation ».</p>
                

                <h4 id="utilisation">Article 4 : UTILISATIONS ET FONCTIONNALITES </h4>
                <p>
                Pour pouvoir utiliser et bénéficier du Service de l’application, l’utilisateur doit d’abord s’identifier.
                 Il accepte ensuite les termes et conditions d’utilisation. C’est à l’utilisateur lui-même de conserver les informations et il en est le seul responsable.

                </p>
                <p>

                Cependant, si la loi l’exige ou lorsque la divulgation d’informations personnelles de l’utilisateur est nécessaire pour des éventuelles enquêtes sur des potentielles infractions, FieldConnect peut les fournir aux autorités concernées.
                </p>
                
                <h4 id="resiliation">Article 5 : DUREE ET RESILIATION </h4>
                <p>
                Le Service est fourni pour une durée indéterminée.
                </p>
                <p>
                La résiliation peut se faire de plein droit par FieldConnect, sans préavis, en cas de manquement aux termes et conditions auquel pourraient résulter des préjudices.

                </p>
                <p>
                Suite à la résiliation de l'accès à FieldConnect, l’utilisateur doit immédiatement cesser de stocker et d'utiliser de quelque manière que ce soit les données personnelles.
                </p>

                <h4 id="obligation">Article  6: OBLIGATIONS DE L’UTILISATEUR</h4>
                <p>
                L’exactitude des informations fournies par l’Utilisateur est garantie par lui-même. Il reste également le seul responsable de ces informations.

                </p>
                <p>
                L’utilisateur se doit également d’utiliser la plateforme dans la mesure de l’objet de celle-ci. Toute exploitation de la plateforme, au-delà de ses fonctionnalités, engage la responsabilité intégrale de l’utilisateur.

                </p>
                
                <h4 id="tarification">Article  7: TARIFICATION</h4>
                <p>
                L’utilisation du service proposé par FieldConnect implique des frais liés au Service, mentionnés dans la section Abonnement de la page d'accueil.

                </p>
                <p>
                Néanmoins, un essai gratuit pendant 30 jours est offert à tous les nouvels utilisateurs.
                </p>


                <h4 id="obligations">Article  8: OBLIGATIONS DE FIELDCONNECT</h4>
                <p>
                FieldConnect s’engage à assurer la sécurité des données et informations fournies par l’Utilisateur.
Il s’engage également à la bonne marche de la plateforme. Ceci étant, la responsabilité de FieldConnect ne peut être engagé en cas de force majeure c’est-à-dire des événements imprévisibles, irrésistibles et extérieurs, en cas de problème de connexion dû au réseau Internet, en cas de préjudice qui n’est pas contrôle par FieldConnect.

                </p>
 

                <h4 id="donnees">Article  9: DONNEES PERSONNELLES</h4>
                <p>
                FieldConnect sera amenée à collecter, traiter et/ou conserver les données à caractère personnel de l’Utilisateur, notamment dans le cadre de l’exécution des présentes qui lui permet de bénéficier du Service. 
                </p>
                <p>
                L’utilisation, l’exploitation du Service « FieldConnect » vaut acceptation de la part du l’Utilisateur de la collecte de ses données à caractère personnel. 
                A cet effet, l’Utilisateur autorise expressément FieldConnect à conserver les informations personnelles le concernant . 
                </p>

                <p>
                En cas d’arrêt d’utilisation, FieldConnect pourra être amenée à conserver les données du Partenaire pour des raisons d’ordre légal, technique et spécifique. 
                </p>

                <p>
                Par ailleurs, préalablement à la souscription au Service et par son utilisation, l’utilisateur accepte la  <Card.Link as={Link} to={Routes.Privacy.path}  target="_blank">Politique de confidentialité</Card.Link>. 
                </p>

                <p>
                En cas de réquisition officielle adressée par les Autorités compétentes, FieldConnect pourra collaborer et communiquer à ces dernières tous les renseignements et informations dont il pourrait avoir connaissance. 
                </p>
                

                <h4 id="reglement">Article  10 : RÈGLEMENT DES DIFFÉRENDS</h4>
                <p>
                Les relations contractuelles de l’Utilisateur sont soumises aux dispositions légales et règlementaires en vigueur à Madagascar
                </p>
                <p>
                Les Parties s’engagent à régler à l’amiable tout litige survenu à l’occasion de l’exécution ou de l’interprétation des dispositions contractuelles ou de ce qui pourrait en être la conséquence. 
                </p>

                <p>
                En cas d’échec desdites procédures de règlement à l’amiable, tout litige sera porté devant les Tribunaux et Cours statuant en matière commerciale d’Antananarivo. 
                </p>



                <h4 id="modifications">Article  11 : MISE EN CONFORMITE – MODIFICATION</h4>
                <p>
                Toutes les dispositions contractuelles existantes entre les Parties devront être mises en conformité en cas de changement ou évolution des dispositions légales, administratives et règlementaires en vigueur, et modifiées en ce sens, sans que cela ne puisse être considéré comme étant une cause pouvant justifier une résiliation du Contrat. 

                </p>
                <p>
                Il en sera de même des modalités tarifaires et financières en cas d’augmentation obligatoire résultant de décisions légales, administratives, gouvernementales et règlementaires, notamment en matière de dévaluation de l’Ariary ou de modification des coûts structurels de FieldConnect permettant ainsi de maintenir l'équilibre du Contrat. 
                </p>

                <p>
                FieldConnect se réserve le droit de modifier ou de façon plus générale, d'actualiser à tout moment et sans notification préalable les présents termes et conditions l’application Web, en fonction de son activité et des offres/services proposées à ses clients. 

                </p>

                <p>
                Suite aux modifications pouvant ainsi être apportées par FieldConnect, la mise à jour des présents termes et conditions générales sera disponible sur la plateforme ou à la demande expresse de l’Utilisateur.

                </p>
                
                
                </article>
                <p>
               La présente termes et conditions est éditée le 09 Aout 2024.
               </p>
            </Card.Body>
            </Card>
        </Col>
        </Row>
    </Container>


    <footer className="footer py-6 bg-dark text-white">
        <Container>
          <Row>
            <Col md={4}>
              <Navbar.Brand as={HashLink} to="#home" className="me-lg-3 mb-3 d-flex align-items-center">
                
                <Image style={{height:50}} src={FieldConnectLogo} />
              </Navbar.Brand>
              <p>La solution idéale pour vos bésoins de création de formulaire dynamique,  qui peut s'adapter à toutes les spécificités de vos activités de collecte de données sur terrain.</p>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Navigation</span>
              <ul className="links-vertical mt-2">
                <li><Card.Link as={HashLink}  to="/#presentation" target="_blank">Présentation</Card.Link></li>
                <li><Card.Link as={HashLink}  to="/#features" target="_blank">Fonctionnalités</Card.Link></li>
                <li><Card.Link as={HashLink} to="/#pricing" target="_blank">Abonnement</Card.Link></li>
                <li><Card.Link as={HashLink}  to="/#contact" target="_blank">Nous contacter</Card.Link></li>
              </ul>
            </Col>
            <Col xs={6} md={2} className="mb-5 mb-lg-0">
              <span className="h5">Autres</span>
              <ul className="links-vertical mt-2">
                <li>
                  {/* <Card.Link as={Link} to={Routes.DocsQuickStart.path} target="_blank">Politique de confidentialité</Card.Link> */}
                </li>
                {/* <li><Card.Link as={Link} to={Routes.DocsChangelog.path} target="_blank">Termes et Conditions</Card.Link></li> */}
                <li><Card.Link  as={Link} to={Routes.TermsAndConditions.path} target="_blank"  >Termes et Conditions</Card.Link></li>
                <li><Card.Link  as={Link} to={Routes.Privacy.path} target="_blank"  >Politique de confidentialité</Card.Link></li>
              </ul>
            </Col>
            <Col xs={12} md={4} className="mb-5 mb-lg-0">
              <span className="h5 mb-3 d-block">La newsletter</span>
              <form action="#">
                <div className="form-row mb-2">
                  <div className="col-12">
                    <input value={emailNewsLetter} onChange={(e) => setEmailNewsLetter(e.target.value)}  type="email" className="form-control mb-2" placeholder="example@company.com" name="email" aria-label="Subscribe form" required />
                  </div>
                  <div className="col-12">
                    <button onClick={(e) => handleNewsLetter(e)} type="submit" className="btn btn-secondary text-dark shadow-soft btn-block" data-loading-text="Sending">
                      <span>
                      {
                      inLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Souscrire"
                      )
                    }
                      </span>
                    </button>
                  </div>
                </div>
              </form>
              <p className="text-muted font-small m-0">Souscrivez sur notre Newsletter pour ne pas manquer tous les mises à jours.</p>
            </Col>
          </Row>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              
              <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
                <p className="font-weight-normal font-small mb-0">Copyright © FieldConnect 2024. Tous droits réservés.</p>
              </div>
              <Card.Link href="https://vntechnology.mg" target="_blank" className="d-flex justify-content-center">
                {/* <Image src={ThemesbergLogo} height={35} className="d-block mx-auto mb-3" alt="Themesberg Logo" /> */}
                <p className="font-weight-normal font-small mb-2">Powered by <span style={{color:"#61DAFB"}} className="ms-2 brand-text">VNTechnology</span>.</p>

              </Card.Link>
            </Col>
          </Row>
        </Container>
      </footer>
      <Toaster   
          position="top-right"
          reverseOrder={true} />
    </>
    );
  
};
