import React, {useState,useContext, useEffect} from "react";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Form, Button, Breadcrumb } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Select from 'react-select';
// import { TRUE } from "sass";
// import { trusted } from "mongoose";

import toast, { Toaster } from 'react-hot-toast'; 
import { useAuth } from '../../pages/AuthContext';

export default () => {
  const history = useHistory()
  const { token } = useAuth();
  const [team, setTeam] = useState([])
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [role, setSelectedRole] = useState('UTILISATEUR SIMPLE');
  const [myrole, setMyrole] = useState([])


  const [name, setName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedGender, setSelectedGender] = useState('');
  const [email, setEmail] = useState("");
  const [plateforme, setPlateforme] = useState("Mobile");
  
  const [reqMail, setReqMail] = useState(false);

  const [leader, setLeader] = useState(false);

  const [isInvalid, setIsInvalid] = useState(false);

  const [leadList, setLeadList] = useState([]);
  const [leadName, setLeadName] = useState('');

  const [user, setUser] = useState([]);
  // setUser(data["users"])

  // const [numero, setNumero] = useState('');
  const userCon =  JSON.parse(localStorage.getItem("user"))

  useEffect(() => {
    // fetch(`/api/getAllUsers/${userCon._id}`, {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': `Bearer ${token}`,
    //   }
    // }).then(response => response.json())
    //   .then(data => {
    //     setUser(data["users"]);
    //     // console.log('user con'+ JSON.stringify(data))
    //   })
      fetch(`/api/getAllUsers/${userCon._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }
      }).then(response => response.json())
        .then(data => {
          setUser(data["users"]);
          // console.log('user con'+ JSON.stringify(data))
        }) 
  }, []);

  useEffect(() => {
      fetch(`/api/getUser/${userCon._id}`).then(
      response => response.json()
    ).then(
      response => {
        setMyrole(response[0].role)
      }
    )}, [])

    useEffect(() => {
      fetch(`/api/getLead/${userCon._id}`).then(
      response => response.json()
    ).then(
      response => {
        const leadList = response['lead'].map((user) => ({
          value: user._id,
          label: user.name + ' ' + user.firstName,
        }));
        setLeadList(leadList) 
      }
    )}, [])


  useEffect(() => {
    fetch(`/api/getAllTeams/${userCon._id}`).then(
        response => response.json()
    ).then(
        data => setTeam(data["teams"])
    )
    console.log('Teams'+ team)
  }, []);

  const teamList = team.map((team) => ({
    value: team._id,
    label: team.name,
  }));

  const handleSelectTeam = (selectedTeam) => {
    const selectedValues = selectedTeam == undefined ? []: selectedTeam.map(team => team.value);
    setSelectedTeam(selectedValues)
  };

  const handleOptionChange = (selectedTeam) => {
    // setSelectedTeam(selectedValues)-+
  };

  const handlePlateforme = (e) => {
    // setSelectedTeam(selectedValues)-+
    const ref       =  e.target.value === 'mobile'? false : true
    setReqMail(ref)
    console.log('plateforme '+plateforme)
    setPlateforme(e.target.value)
  };

  const handleLeader = (e) => {
    // setSelectedTeam(selectedValues)-+\
    console.log(e.target.value)
    // const lead       =  e.target.value === 'on'? true : false
    setLeader(e.target.checked);
    // setLeader(lead)
    console.log('Leader '+leader)
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setPhoneNumber(value);
  //  setPhoneNumber(e.target.value)
    // Vérifiez si le contenu n'est pas un nombre ou a moins de 10 caractères
    setIsInvalid(isNaN(value) || value.length != 10);
  };

  const handleSelectedRole = (event) => { 
    const value = event.target.value;
    console.log('role user'+value)
    if(value != 'UTILISATEUR SIMPLE') {
      setLeadName('')
    } 
    setSelectedRole(value);
  }

  const handleSelectLead = (event) => {
    const value = event.target.value;
    setLeadName(value);
  };


  
//   setSelectedRole
  // const [selectedData, setSelectedData] = useState([]);

  // const handleSelectionChange = (selectedData) => {

  //  const selected =  selectedData.map((item) =>  console.log('check slelect'+item))


  //   console.log('check slelect'+selected)
  //   setSelectedData(selectedData);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const company =  JSON.parse(localStorage.getItem("user")).company
    if (!isInvalid && phoneNumber.length == 10) {
      console.log('all user' + {name,firstName,email,phoneNumber,company,role, team:selectedTeam,gender:selectedGender,plateforme,leader})
      const leadId = leadName != "" ? leadName : null
      const response = await fetch('/api/createUser', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({name,firstName,email,phoneNumber,company,role, team:selectedTeam,gender:selectedGender,plateforme,leadId}),
    }).then(
      response => response.json()
    ).then(
      data => {
        if (data.status === 'success') {
          toast.success('Création réussie', {
            style: {
              border: '1px solid #713200',
              padding: '16px',
            }});
          history.push("/user");
        } else {
          toast.error(data['message'], {
              style: {
                border: '1px solid #713200',
                padding: '16px',
              }});
          console.error(data.message);
        }
      }
    )

  }else{
      toast.error('Le champ doit contenir uniquement des chiffres et 10 caractères.', {
      style: {
        border: '1px solid #713200',
        padding: '16px',
      }});
  }
    // const data = await response.json();

    // if (data.success) {
    //       toast.success('Creation reussie', {
    //         style: {
    //           border: '1px solid #713200',
    //           padding: '16px',
    //         }});
    //   history.push("/user");
    // } else {
    //       toast.success(data['message'], {
    //         style: {
    //           border: '1px solid #713200',
    //           padding: '16px',
    //         }});
    //   console.error(data.message);
    // }
    
  };
 

  return ( 
    <>
    <div style={{  marginTop: '-20px'}} className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item  linkAs={Link} linkProps={{ to: '/user' }}>Utilisateurs</Breadcrumb.Item>
            <Breadcrumb.Item active>Créer</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Créer un nouveau Utilisateur</h4>
        </div>
        
      </div>


  
  <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Form onSubmit={handleSubmit} className="mt-4">

        <Row>
            < Col className="mb-3" md={2} >
              <Form.Group id="plate">
                  <Form.Label>Plateforme</Form.Label>
                  <Form.Select
                        onChange={handlePlateforme}> 
                        <option value="mobile">Mobile</option>
                        <option value="web">Web</option>
                        <option value="both">Les deux</option>
                </Form.Select>
              </Form.Group>
            </Col>
        </Row>
          <Row>
            <Col className="mb-3" md={3} >
              <Form.Group id="firstName">
                <Form.Label>Nom</Form.Label>
                <Form.Control onChange={(e) => setName(e.target.value)}  required type="text"/>
              </Form.Group>
            </Col>
            <Col className="mb-3"  md={3}> 
              <Form.Group id="note">
                <Form.Label>Prénom</Form.Label>
                <Form.Control onChange={(e) => setFirstName(e.target.value)}  required type="text" rows={3} />
              </Form.Group>
            </Col>
          </Row>
         
          <Row>
            <Col className="mb-3"  md={3}>
              <Form.Group id="note">
                <Form.Label>Mail</Form.Label>
               <Form.Control onChange={(e) => setEmail(e.target.value)} required={reqMail}  type="email" placeholder="name@example.com" rows={3} />
              </Form.Group>
            </Col>

            <Col className="mb-3"  md={3}>
              <Form.Group id="tel">
                <Form.Label>Numéro Téléphone</Form.Label>
                          <Form.Control 
                          type="text"
                          placeholder="Entrez numéro Téléphone"
                          value={phoneNumber}
                          onChange={handleChange}
                          isInvalid={isInvalid}
                          required={!reqMail} rows={3} />
                 <Form.Control.Feedback type="invalid">
                     Le champ doit contenir uniquement des chiffres et 10 caractères.
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
                <Col className="mb-3"  md={3}> 
                  <Form.Group id="note">
                      <Form.Label>Genre</Form.Label>
                      <Form.Select
                            // value={selectedGender}
                            onChange={(e) => setSelectedGender(e.target.value)}> 
                            <option value=""></option>
                            <option value="Feminin">Féminin</option>  
                            <option value="Masculin">Masculin</option>
                      </Form.Select>
                  </Form.Group>
                </Col>
                <Col className="mb-3"  md={3}> 
                <Form.Group id="note">
                    <Form.Label>Rôle</Form.Label>
                    {/* <Form.Select
                          // value={role}
                          onChange={(e) => setSelectedRole(e.target.value)}> 
                          <option value="ADMIN">ADMIN</option>
                          <option value="VIEWER">VIEWER</option>
                          <option value="EDITOR">EDITOR</option>
                    </Form.Select> */}
                    <Form.Select
                            // name= "role"
                            value={role}
                            onChange={handleSelectedRole}> 
                           {myrole != 'ADMIN' ?(
                                <option value="UTILISATEUR SIMPLE">UTILISATEUR SIMPLE</option>
                           ):(
                            <>
                                  <option value="ADMIN">ADMIN</option>
                                  <option value="RESPONSABLE">RESPONSABLE</option>
                                  <option value="UTILISATEUR SIMPLE">UTILISATEUR SIMPLE</option>
                           </>) 
                          } 
                    </Form.Select>

                    
                </Form.Group>
                </Col>
                
          </Row>

          {/* <Row>
              <Col className="mb-3" md={1}>
                  <Form.Group>
                      <Form.Check // prettier-ignore
                        type="switch" 
                        id="custom-switch"
                        label="Responsable"
                        checked={leader}
                        onChange={handleLeader}
                      />
                  </Form.Group>
                </Col>
          </Row> */}
          <Row>
              <Col className="mb-3" md={3}>
                <Form.Group>
                  <Form.Label>Equipes</Form.Label>
                  <Select
                      isMulti
                      options  = {teamList}
                      onChange = {handleSelectTeam}
                      placeholder = 'Rattacher à une équipe'
                  />
                </Form.Group>
              </Col>
              <Col className="mb-3" md={3}>
              <Form.Group id="plate">
                  <Form.Label>Nom Responsable</Form.Label>
                  <Form.Select  value={leadName}
                        onChange={handleSelectLead} disabled = {role == 'UTILISATEUR SIMPLE'? false : true }> 
                        <option value = '' ></option>
                        {leadList &&
                          leadList.map(l => <option value = {l.value} >{l.label}</option>)
                        }
                </Form.Select>
              </Form.Group>
            </Col>


             
          </Row>

          {/* onSelectionChange={handleSelectionChange} */}
            {/* <FormsTags dataFromDatabase={tags} onSelectionChange={handleSelectionChange}/> */}
          <div className="mt-3">
            <Button variant="primary" type="submit">Créer</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
    <div>
        <Toaster   
          position="top-right"
          reverseOrder={true} />
      </div>
    </>   
  );
};
