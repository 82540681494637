import React from 'react';
import store from '../components/react-form-builder/stores/store';
import toast, { Toaster } from 'react-hot-toast'; 
import { ReactFormGenerator } from '../components/react-form-builder/index';
import { withRouter  } from 'react-router-dom';
import Swal from 'sweetalert';
import { useAuth } from './AuthContext';

const answers = {};
const saveUrl = "http://95.216.23.156:5005/api/prospecting";

// const answers = {
//   'dropdown_38716F53-51AA-4A53-9A9B-367603D82548': 'd2',
//   'checkboxes_8D6BDC45-76A3-4157-9D62-94B6B24BB833': [
//     'checkboxes_option_8657F4A6-AA5A-41E2-A44A-3E4F43BFC4A6',
//     'checkboxes_option_1D674F07-9E9F-4143-9D9C-D002B29BA9E4',
//   ],
//   'radio_buttons_F79ACC6B-7EBA-429E-870C-124F4F0DA90B': [
//     'radiobuttons_option_553B2710-AD7C-46B4-9F47-B2BD5942E0C7',
//   ],
//   'rating_3B3491B3-71AC-4A68-AB8C-A2B5009346CB': 4,
// };

class Demobar extends React.Component {
  

  constructor(props) {
    super(props);
    
    // console.log('forms infos'+this.props.forms_info)

    this.state = {
      data: [],
      forms_info: props.forms_info || {}, //custom field
      server_response: {},//custom field
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    };
    // this.history = useHistory();


    const update = this._onChange.bind(this);
    
    this._onSubmit = this._onSubmit.bind(this);

    store.subscribe(state => update(state.data));
  }


  showPreview() {
    // this.saveFormData();
    this.setState({
      previewVisible: true,
    });
  }

  showShortPreview() {
    // this.saveFormData();
    this.setState({
      shortPreviewVisible: true,
    });
  }

  showRoPreview() {
    // this.saveFormData();
    this.setState({
      roPreviewVisible: true,
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    });
  }

  _onChange(data) {
    this.setState({
      data,
    });
  }

  // eslint-disable-next-line no-unused-vars
  _onSubmit(data) {
    // console.log('onSubmit', data);
    // Place code to post json data to server here
  }

  processCheckUpdate(old_forms, new_forms) {
    
    let new_keys = []


    Object.keys(new_forms).forEach(key=>{
      new_keys.push(new_forms[key].id)
    })

    Object.keys(old_forms).forEach(key=>{
      if(!new_keys.includes(old_forms[key].id) ) {
        old_forms[key].deleted = true
        new_forms.push(old_forms[key])
      }
    })

    return new_forms
  }

  saveFormData() {
    const token = localStorage.getItem("accessToken")
   
    if(this.state.forms_info.content) {
      let new_forms = this.processCheckUpdate( JSON.parse(this.state.forms_info.content), this.state.data)

      // update
      fetch("/api/updateforms/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ content_form: new_forms, forms_info: this.state.forms_info  }),
      }).then(
          
          response => response.json()
      ).then(
        // data => console.log(data)
          data => {
            if(data.status === "success") {
              swal({
                title: "Succès",
                text: data['message'],
                icon: "success",            
              })
              .then((result) => {
                console.log(result)
                if (result) {
                  this.props.history.push(`/viewprospection/${data['prospecting']['_id']}`);
                } 
              });
            }else if(data.status === "session_expired") {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('user')
              swal({
                title: "Session expirée",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
              this.props.history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
            
          }
          
      )
      

    }else {
      // create
      console.log('forms infos'+ JSON.stringify(this.props.forms_info) )

      fetch("/api/prospecting/", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({userId :this.props.forms_info.userId,  formsId : this.props.forms_info.idProject, content_form: this.state.data}),
      }).then(
          
          response => response.json()
      ).then(
        // data => console.log(data)
          data => {

            if(data.status === "success") {
              
              swal({
                title: "Succès",
                text: data['message'],
                icon: "success",            
              })
              .then((result) => {
                console.log(result)
                if (result) {
                  this.props.history.push(`/viewprospection/${this.props.forms_info.idProject}`);
                } 
              });
            }else if(data.status === "session_expired") {
              localStorage.removeItem('accessToken')
              localStorage.removeItem('user')
              swal({
                title: "Session expirée",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
              this.props.history.push("/sign-in");
            }else {
              swal({
                title: "Erreur",
                text: data.message,
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then((result) => {
                console.log(result)
              });
            }
            
  
            
          }
          
      )
    }
    
  
    
  }


  render() {
    
    let modalClass = 'modal';
    if (this.state.previewVisible) {
      modalClass += ' show d-block';
    }

    let shortModalClass = 'modal short-modal';
    if (this.state.shortPreviewVisible) {
      shortModalClass += ' show d-block';
    }

    let roModalClass = 'modal ro-modal';
    if (this.state.roPreviewVisible) {
      roModalClass += ' show d-block';
    }

    return (
     
      <div className="clearfix" style={{ marginBottom: '10px', marginTop: '-20px', width: '70%' }}>
         <div>
            <Toaster   
              position="top-right"
              reverseOrder={false} />
          </div>
        <button className="btn btn-primary float-right" style={{ marginRight: '10px' }} onClick={() => this.showPreview()}>Aperçu Formulaire</button>
        {/* <button className="btn btn-default float-right" style={{ marginRight: '10px' }} onClick={() => this.showShortPreview()}>Alternate/Short Form</button>
        <button className="btn btn-default float-right" style={{ marginRight: '10px' }} onClick={() => this.showRoPreview()}>Read Only Form</button> */}
        <button className="btn btn-primary float-right" style={{ marginRight: '10px' }} onClick={() => this.saveFormData()}>Sauvegarder</button>

        { this.state.previewVisible &&
          <div className={modalClass} role="dialog">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                
                <ReactFormGenerator
                  download_path=""
                  // back_action="/"
                  // back_name="Retour"
                  answer_data={answers}
                  action_name="Sauvegarder"
                  form_action="/api/form"
                  form_method="POST"
                  // skip_validations={true}
                  // onSubmit={this._onSubmit}
                  variables={this.props.variables}
                  data={this.state.data}
                  locale='en'/>
                  <div style={{paddingBottom:50}} className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.closePreview.bind(this)}>Fermer</button>
                  </div>
                
              </div>
            </div>
          </div>
        }

        { this.state.roPreviewVisible &&
          <div className={roModalClass}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <ReactFormGenerator
                  download_path=""
                  back_action="/"
                  back_name="Back"
                  answer_data={answers}
                  action_name="Save"
                  form_action="/"
                  form_method="POST"
                  read_only={true}
                  variables={this.props.variables}
                  hide_actions={true}
                  data={this.state.data}
                  locale='en'/>

                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.closePreview.bind(this)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        }

        { this.state.shortPreviewVisible &&
          <div className={shortModalClass}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content border border-light p-3 mb-4">
                <ReactFormGenerator
                  download_path=""
                  back_action=""
                  answer_data={answers}
                  form_action="/"
                  form_method="POST"
                  data={this.state.data}
                  display_short={true}
                  variables={this.props.variables}
                  hide_actions={false}
                  locale='en'
                  />

                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={this.closePreview.bind(this)}>Close</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}


export default withRouter(Demobar);