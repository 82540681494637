import React, {useState,useEffect,useContext} from "react";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Form, Button, InputGroup, Breadcrumb,ButtonGroup,Tab,Tabs, Tooltip, OverlayTrigger, Badge   } from '@themesberg/react-bootstrap';
import { Link, useParams,useHistory } from 'react-router-dom';
import { ReactFormGenerator } from '../components/react-form-builder/index';
import jwt_decode from 'jwt-decode';

import { FormsTable } from '../components/FormsTable';
import { FormsGalerie } from '../components/FormsGalerie';
require('../scss/react-form-builder/application.scss');
import { useAuth } from './AuthContext';

import Select from 'react-select';




export default () => {
  const history = useHistory()
  const [ userId, setUserId ] = useState();
  const { token } = useAuth();

  const userCon = JSON.parse(localStorage.getItem("user"))


  const state = {
    draft: "Brouillon",
    deployed: "Déployé"
  }

  // useEffect(() => {
  //   const checkUserConnected = () => {
  //     try {
  //       const token = localStorage.getItem("accessToken")
  //       const user = localStorage.getItem("user")
  //       const decodedToken = jwt_decode(token);
  //       if(token == null || decodedToken.userId == null) {
  //         history.push("/sign-in");
  //       }else {
  //         setUserId(decodedToken.userId)
  //         setUserCon(JSON.parse(user))
  //       }
  //     }catch(err) {
  //       history.push("/sign-in");
  //     }
  //   }
  //   // checkUserConnected();
  // }, []);

  const routeParams  = useParams();
  const formId = routeParams.id
  const [prospecting, setProspecting] = useState([])
  const [formsData, setFormsData] = useState([])
  const [formsImages, setFormsImages] = useState([])
  const [imagesSize, setFormsImagesSize] = useState(null)
  
  const [defaultTeam, setDefaultTeam] = useState([])
  const [forceLocation, setForceLocation] = useState(true)
  const [onEdit, setOnEdit] = useState(false)
  const [prospectingName, setProspectingName] = useState("");
  const [prospectingNote, setProspectingNote] = useState("");
  const [teamList, setTeamList] = useState([])
  const [selectedTeam, setSelectedTeam] = useState([])


  let options = {
    note: prospecting.note,
    teams: prospecting.teams 
  }
  const [previewVisible, setPreviewVisible] = useState(false)
  let modalClass = 'modal';
  if (previewVisible) {
    modalClass += ' show d-block';

  }


  const handleChangeTeam = (selectedOptions) => {
    // console.log('selected values'+JSON.stringify(selectedOptions) )
    // team
    let t_obj = selectedOptions === null ? [] : selectedOptions.map(option => option.value)
    // console.log(t_obj)
    setSelectedTeam(t_obj)
  };
  

  const handleState = (forms) => {
    // console.log(forms)
    fetch(`/api/handleState/${forms._id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }}).then(
      response => response.json()
      
    ).then(
        data => {
          console.log(data)
          if(data.status == "success") {
            setProspecting(data.prospecting)
            swal({
              title: "Succès",
              text: data['message'],
              icon: "success",            
            })
            .then((result) => {
              console.log(result)
              
            });
          } else {
            swal({
              title: "Echec",
              text: data['message'],
              icon: "error",            
            })
            .then((result) => {
              console.log(result)
              
            });
          }
          
        }
    )
  }

  const modifyForms = () => {
    if(onEdit) {
      // console.log(userId, prospecting._id,prospectingName, prospectingNote, forceLocation, selectedTeam)
      fetch('/api/updateformsinfo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({userId : userCon._id, formId:prospecting._id,prospectingName, prospectingNote, forceLocation, selectedTeam}),
      }).then(
        response => response.json()
      ).then(
        data => {
          setOnEdit(!onEdit)
          if (data.status === "success") {
            swal({
              title: "Succès",
              text: data['message'],
              icon: "success",            
            })
            .then((result) => {
              console.log(result)
              
            });
          } else {
            swal({
              title: "Erreur",
              text: data['message'],
              icon: "error",            
            })
            .then((result) => {
              console.log(result)
              
            });
          }
        }
      );
    }else {
      setOnEdit(!onEdit)
    }
    



  }

// const getAllTeam = async () => { 
//     await   fetch(`/api/getTeam/${userCon.company}`).then(
//             response => response.json()
//         ).then(
//           response => {
//             const transformedData = response["teams"].map(team => ({
//               value: team._id,
//               label: team.name,
//             }));
//             setTeam(transformedData);
//             console.log('list all team'+JSON.stringify(response))
//         }
//             // data => setTeam(data["teams"])
//         );
//   }

const getTeamUser =  () => { 
  fetch(`/api/getTeam/${userCon._id}`).then(
    response => response.json()
  ).then(
    response => {
        const transformedData = response["teams"].map(team => ({
          value: team._id,
          label: team.name,
        }));
      
        setTeamList(transformedData)
    }
  )
};



useEffect(() => {
  getTeamUser()
}, []);

  useEffect( ()=> {
    let mounted = true;
    async function fetchData(){   
      fetch(`/api/getform/${formId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }}).then(
        response => response.json()
        
      ).then(
          data => {
            if(mounted) {
              setProspecting(data["prospecting"][0])
              setProspectingName(data["prospecting"][0].name)
              setProspectingNote(data["prospecting"][0].note)
              
              let values = data["prospecting"][0]["teams"].map(option => {
                return {label: option.name, value:option._id}
              })
              let Selectdvalues = data["prospecting"][0]["teams"].map(option => {
                return option._id
              })
              setDefaultTeam(values)
              setSelectedTeam(Selectdvalues)
              setForceLocation(data["prospecting"][0].forceLocation)
            }
          }
      )
    };
   
    fetchData();
    // fetchDataForms();
    // fetchImagesForms();
    return ()=> {mounted = false}

}, [])

const handleNameChange = (event) => {
  setProspectingName(event.target.value);
};
const handleNoteChange = (event) => {
  setProspectingNote(event.target.value);
};

const handleForceLocationChange = (event) => {
  setForceLocation(!forceLocation);
  
};




return( 
    <>
    
    <div style={{  marginTop: '-20px'}} className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/forms-project' }}>Projet</Breadcrumb.Item>
            <Breadcrumb.Item active>{prospecting.name}</Breadcrumb.Item>
          </Breadcrumb>
          {/* <h4>Prospection</h4> */}
          {/* <p className="mb-0">Gérer votre prospection selon votre besoin.</p> */}
          {/* <div className="btn-toolbar mb-2 mb-md-0">
              <ButtonGroup>
                <Button variant="outline-primary" size="sm">Données</Button>
              </ButtonGroup>
          </div> */}
        </div>
        <div className="btn-toolbar mb-2 mb-md-0">
          {/* <ButtonGroup>
            <Button variant="outline-primary" size="sm" onClick={() => setPreviewVisible(true)}>Aperçu</Button>
          </ButtonGroup> */}
        </div>
      </div>
      
                <Tabs
                      defaultActiveKey="home"
                      id="justify-tab-example"
                      className="mb-3"
                      justify
                  >
                  <Tab eventKey="home" title="INFORMATIONS SUR LE PROJET">
                      <Card border="light" className="bg-white shadow-sm mb-4">
                        <Card.Body>

                        <div className="table-settings mb-4">
                              <Row className="justify-content-between align-items-center">
                              <Col xs={8} md={6} lg={3} xl={4}>
                                  <span className="mb-4" style={{fontWeight:"bold", marginRight:5}}>Informations Générales</span><Badge bg={prospecting.state == 'draft' ? "danger" : "success"} className="badge-lg">{state[prospecting.state]}</Badge>

                              
                              </Col>
                              
                                <Col xs={8} md={6} lg={3} xl={4} style={{textAlign:"right"}}>
                                  <OverlayTrigger
                                    
                                    key="example"
                                    overlay={<Tooltip id="top" className="m-0">{prospecting.state == 'draft' ? "Publier le formulaire" : "Remettre en brouillon"}</Tooltip>}
                                  >
                                    {
                                      (userCon != undefined && ['RESPONSABLE','ADMIN'].includes(userCon.role)) ? (
                                        <>
                                          <ButtonGroup>
                                            <Button onClick={() => modifyForms()}  variant="primary" size="sm">{!onEdit ? "Modifier" : "Sauvegarder"}</Button>
                                          </ButtonGroup>
                                          <Button onClick={() => handleState(prospecting)} variant={prospecting.state == 'draft' ? "success": "danger"} size="sm" className="m-2">
                                          {prospecting.state == 'draft' ? "Déployer" : "Remettre en brouillon"}
                                          </Button>
                                        </>
                                      ) :
                                      (<></>)
                                      } 
                                    
                                  </OverlayTrigger>    
                                </Col>
                                
                              </Row>
                          </div>
                
              
                <Form>
                  <Row>
                    <Col  className="mb-3">
                      <Form.Group id="firstName" > 
                      {/* {backgroundColor:"#e9e8e8"} */}
                        <Form.Label>Nom</Form.Label>
                        <Form.Control value={prospectingName} readOnly={!onEdit} onChange={handleNameChange}   type="text" />
                      </Form.Group>
                    </Col>
                    
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <Form.Group id="note">
                        <Form.Label>Description</Form.Label>
                        <Form.Control  value={prospectingNote}  onChange={handleNoteChange}   readOnly={!onEdit}   as="textarea" rows={3}  />
                      </Form.Group>
                    </Col>
                  </Row>

                  

                  <Row>
                    <Col className="mb-3">
                    <Form.Group>
                      <Form.Label>Equipe</Form.Label>
                          {defaultTeam.length > 0 ? (
                          <div>
                            <Select
                                  isDisabled={!onEdit} 
                                  defaultValue={defaultTeam}
                                  isMulti
                                  options={teamList}
                                  onChange={handleChangeTeam}
                              />
                          </div>
                        ) : (
                          <Select
                            isDisabled={!onEdit} 
                              defaultValue={defaultTeam}
                              isMulti
                              options={teamList}
                              onChange={handleChangeTeam}
                            />
                        )}

                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="mb-3">
                      <Form.Group style={{marginTop:30}}>
                          <Form.Check // prettier-ignore
                            disabled={!onEdit}   
                            type="switch"
                            id="custom-switch"
                            label="Forcer la coordonnées GPS"
                            name="leader"
                            checked={forceLocation}
                            onChange={handleForceLocationChange}
                          />
                      </Form.Group>
                    </Col>
                    
                  </Row>
                </Form>
              </Card.Body>
            </Card>
        </Tab>
      <Tab eventKey="profile" title="DONNÉES">
      {formId &&
        <FormsTable  formId={formId} formsName = {prospecting.name} />
      }
      </Tab>
      <Tab eventKey="galerie" title="GALERIE">
           
            <FormsGalerie formId={formId} data={formsImages} imagesSize={imagesSize}/> 
      </Tab>
     
      <Tab eventKey="apercu" title="APERCU">
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
            <div className="d-block mb-4 mb-md-0">
            
            {/* <h4>Formulaires</h4>
            <p className="mb-0">Créer votre formulaire selon votre besoin.</p> */}
          </div>
          <div className="btn-toolbar mb-2 mb-md-0" >
          {
            (userCon != undefined && ['RESPONSABLE','ADMIN'].includes(userCon.role)) ? (
              <ButtonGroup>
                {/* <Button variant="outline-primary" size="sm">Remplir</Button> */}
                <Button as={Link} to={{pathname: `/modifyforms/${prospecting._id}`, state:{formId: prospecting._id, formName:prospecting.name, options:options}}} variant="outline-primary" size="sm">Modifier</Button>
              </ButtonGroup>
            ) :
            (<></>)
          } 
            
          </div>
        </div>
        <Card border="light" className="bg-white shadow-sm mb-4">
        
            <Card.Body>
            
              
              { prospecting.content &&
                <ReactFormGenerator
                      download_path=""
                      // back_action="/"
                      // back_name="Retour"
                      action_name="Sauvegarder"
                      form_action="/api/form"
                      form_method="POST"
                      // skip_validations={true}
                      // onSubmit={this._onSubmit}
                      data={JSON.parse(prospecting.content)}
                      locale='en'/>
              }
            </Card.Body>
          </Card>

        
      
      
      </Tab>
      
    </Tabs>
  


    {/* { previewVisible &&
          <div className={modalClass} role="dialog">
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <ReactFormGenerator
                  download_path=""
                  // back_action="/"
                  // back_name="Retour"
                  action_name="Sauvegarder"
                  form_action="/api/form"
                  form_method="POST"
                  // skip_validations={true}
                  // onSubmit={this._onSubmit}
                  data={JSON.parse(prospecting.content)}
                  locale='en'/>

                <div className="modal-footer">
                  <button type="button" className="btn btn-default" data-dismiss="modal" onClick={() => setPreviewVisible(false)}>Fermer</button>
                </div>
              </div>
            </div>
          </div>
        } */}
    
    </>
  );
};
