import React,{useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCashRegister,faChartLine, faCloudUploadAlt,faUsers,faTable , faDatabase,faUser,faCity , faPlus, faRocket, faTasks, faUserShield } from '@fortawesome/free-solid-svg-icons';
// import { Col, Row, Button, Dropdown, ButtonGroup } from '@themesberg/react-bootstrap';
import {Form ,  Breadcrumb,Button,Col, Row , Nav, Card, Image, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';

import { CounterWidget,  FormsValueWidget } from "../../components/Widgets";

import jwt_decode from 'jwt-decode';
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios';
// import ReactApexChart  from 'apexcharts';
// import ApexCharts from 'apexcharts'
import Chart from 'react-apexcharts';

// import HighchartsReact from 'highcharts-react-official';
// import 'chartist/dist/chartist.min.css';
// import ShowMap from '../../components/ShowMap';
// import PuffLoader  from "react-spinners/ClipLoader";

import {  faComment } from '@fortawesome/free-solid-svg-icons';
// import Highcharts from 'highcharts';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,AreaChart,Area } from 'recharts';
import DataTable from 'react-data-table-component';
import { useAuth } from '../AuthContext';

export default () => {
  const history = useHistory()
  const [ userId, setUserId ] = useState();// useContext(AuthContext);
  const [ formsData, setFormsData ] = useState([])
  const [ formsLocation, setFormsLocation ] = useState([])


  const [ usersOnline, setUsersOnline ] = useState([])
  const userCon  =  JSON.parse(localStorage.getItem("user"))
  const [ formsId, setFormsId] = useState("")
  const [ leadId, setLeadId] = useState("")
  const [ selectedUser, setSelectedUser] = useState("")

  const [ colTr, setcolTr] = useState("grey")
  // const [selectedRow, setSelectedRow] = useState("idForms");
  const [ nbTeam, setNbTeam] = useState(0)
  const [ nbForm, setNbForm] = useState(0)
  
  const [formsDaily, setFormsDaily]= useState([])
  const legendFormatter = (value, entry, index) => {
    const customNames = { nbForms: 'NB' };
    return customNames[value] || value;
  };
  
  const chartOptions = {
    chart: {
      type: 'line',
      height: 350,
    },
    title: {
      text: 'Evolution données collectées',
      align: 'left'
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    },
    yaxis: {
      title: {
        text: 'Values',
      },
    },
  };

  // Example data series
  const chartSeries = [
    {
      name: 'Series 1',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ];


const [formsLead, setFormsLead] = useState([])
const [activeButton, setActiveButton] = useState('daily');
const { token } = useAuth();


// console.log('user informations'+ JSON.stringify(userCon) )
async function fetchDaily(forms,user,leadId,activeButton) {

  const res = await axios.post('/api/getDailyResponse', {idConnected :userCon._id ,idForms: forms,leadId:leadId,userSelected:user,period : activeButton}, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  setFormsDaily(res.data.forms)
}

 function fetchLead(forms){
  fetch('/api/getFormsLead', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({userId :userCon._id ,idForms: forms}),
     }).then(
      response => response.json()
    ).then(
      data => {
        setFormsLead(data['forms'])
        console.log('resulta' + JSON.stringify(data))
      })
}

function fetchUserOnline(leadId,formsId){
  fetch('/api/getOnlineUser', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({userId :userCon._id,leadId : leadId ,idForms: formsId}),
     }).then(
      response => response.json()
    ).then(
        data => {
          
          if(data.status === "success") { 
              const onUser = ( data['users'].length == 0 ) ? ([]) : (
                data['users'].map((user) => ({
                  _id : user._id,
                  "name": user.name +" "+ user.firstName,
                  nbForms : user.nbForms,
                  connected : user.connected
              })) 
              )
            setUsersOnline(onUser)
          }else if(data.status === "session_expired") {
            console.log('users online'+JSON.stringify(data) )
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user')
            swal({
              title: "Session expiréeberbfed",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
            history.push("/sign-in");
          }else {
            swal({
              title: "Erreur",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
          }
          
        }
    )
  }

useEffect(() => {
    function fetchForms(){
              fetch(`/api/getAllFormsResponse/${userCon._id}`).then(
                response => response.json()
            ).then(
                data => {
                 
                  if(data.status === "success") {
                    // console.log('forms data'+JSON.stringify(data) )
                    setFormsData(data['forms'])
                  }else if(data.status === "session_expired") {
                    console.log('forms data'+JSON.stringify(data) )
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('user')
                    swal({
                      title: "Session expirée fornms",
                      text: data.message,
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    })
                    .then((result) => {
                      console.log(result)
                    });
                    history.push("/sign-in");
                  }else {
                    swal({
                      title: "Erreur",
                      text: data.message,
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    })
                    .then((result) => {
                      console.log(result)
                    });
                  }
                  
                }
          )
    }


  function fetchNbteam(){
    fetch(`/api/getNbteam/${userCon._id}`).then(
      response => response.json()
    ).then(
        data => {
          setNbTeam(data['nb'])
        })
  }

  function fetchNbForms(){
    fetch(`/api/getNbforms/${userCon._id}`).then(
      response => response.json()
    ).then(
        data => {
          setNbForm(data['nb'])
        })
  }

   

    fetchUserOnline(leadId,formsId)
    fetchForms();
    fetchNbForms();
    fetchNbteam();
    fetchLead(formsId)
    // console.log('froms' + formsId)
    // fetchDaily(formsId,selectedUser)
    // fetchFormsLocation(formsId)
    
  }, [formsId,selectedUser]);

 
  useEffect(() => {
    fetchDaily(formsId,selectedUser,leadId,activeButton)
  }, [activeButton,formsId,selectedUser,leadId]);


const columnsUser = [
      {
        name: 'NOM',
        selector:row => row.name,
        sortable: true,
        // width: '60px'
      },
      {
        name: 'DONNÉES',
        selector:row =>row.nbForms,
        sortable: true,
        // width: '60px'
      }
]
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
      // backgroundColor: '#1f2334', 
      color : 'black'// You can set your desired background color here
    },
  },
};


const columnsLead = [
  {
    name: 'NOM',
    selector:row => row.leadName,
    sortable: true,
    // width: '60px'
  },
  {
    name: 'DONNÉES',
    selector:row =>row.nbForms,
    sortable: true,
    // width: '60px'
  }
]
const columnsForms = [
  {
    name: 'PROJET',
    selector:row => row.formName,
    sortable: true,
    // width: '60px'
  },
  {
    name: 'DONNÉES',
    selector:row =>row.nbForms,
    sortable: true,
    // width: '60px'
  }
  // {
  //   name: 'Utilisateurs',
  //   selector:row =>row.nbUsers,
  //   sortable: true,
  //   // width: '60px'
  // }
]

const conditionalRowStyles = [
  {
    when: row => row.prospectingId === formsId,
    style: {
      backgroundColor: '#1f2334',
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
];

const conditionalRowStylesLead = [
  {
    when: row => row._id === leadId,
    style: {
      backgroundColor: '#1f2334',
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
];

const conditionalRowStylesUser = [
  {
    when: row => row._id === selectedUser,
    style: {
      backgroundColor: '#1f2334',
      color: 'white',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
];

const changeForms = (props) => {
  let id =  props.prospectingId   == formsId ? "": props.prospectingId
  setFormsId(id)
  // fetchDaily(id,selectedUser,leadId)
  fetchLead(id)
}

const changeLead = (props) => {
  let id = props._id   == leadId ? "": props._id
  setLeadId(id)
  fetchUserOnline(id,formsId)
  // fetchDaily(formsId,selectedUser,id)

}

const changeUser = (props) => {
  let id = props._id   == selectedUser ? "": props._id
  setSelectedUser(id)
  // fetchDaily(formsId,id,leadId)
//   fetchLead(id)
  // setLeadId(props._id)
}

const handleButtonClick = (buttonName) => {

  console.log(buttonName)
  setActiveButton(buttonName);
};
// console.log( "fors daily "+ JSON.stringify(formsDaily) )


  return (
    <>
      {/* <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"> */}
        

        {/* <ButtonGroup>
          <Button variant="outline-primary" size="sm">Share</Button>
          <Button variant="outline-primary" size="sm">Export</Button>
        </ButtonGroup> */}
      {/* </div> */}
      <Row>
          <Col xs={8} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="Données"
              title={ formsData == undefined ?0:  formsData.reduce((acc, obj) => acc + obj.nbForms, 0)}
              period="28 Apr"
              // percentage={28.4} 
              icon={faDatabase}
              iconColor="shape-tertiary"
            />
          </Col>
          <Col xs={8} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="Projet"
              title={nbForm == undefined ?0:nbForm}
              period="28 Apr"
              percentage={18.2}
              icon={faTable}
              iconColor="shape-secondary"
            />
          </Col>
          

        
          <Col xs={8} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="Utilisateurs"
              title={usersOnline == undefined ?0:usersOnline.length}
              period="28 Apr"
              percentage={usersOnline == undefined ?0: usersOnline.length}
              icon={faUser}
              iconColor="shape-secondary"
            />
          </Col>
          <Col xs={8} sm={6} xl={3} className="mb-4">
              <CounterWidget
                  category="Equipes"
                  title={nbTeam}
                  period="28 Apr"
                  percentage={nbTeam}
                  icon={faUsers}
                  iconColor="shape-secondary"
                />
          </Col>

          {/* <Col xs={8} sm={6} xl={3} className="mb-4">
            <CounterWidget
              category="NB Equipe"
              title="$43,594"
              period="Feb 1 - Apr 1"
              percentage={28.4} 
              icon={faUsers}
              iconColor="shape-tertiary"
            />
          </Col> */}
         
          
      </Row>
      {/* className="justify-content-md-center" */}
      <Row>
      <Col xl={8} className="mb-4 d-none d-sm-block">
          <Row>
        
            <Col xs={6} xl={12} className="mb-4 d-none d-sm-block">
            <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                    <h5>Evolution données collectées</h5>
                    <ButtonGroup aria-label="Basic example">
                          <Button
                            onClick={() => handleButtonClick('daily')} variant={activeButton === 'daily' ? 'primary' : 'secondary'}>
                            Journalier
                          </Button>
                          <Button
                            onClick={() => handleButtonClick('weekly')} variant={activeButton === 'weekly' ? 'primary' : 'secondary'}>
                            Hebdomadaire
                          </Button>
                          <Button
                            onClick={() => handleButtonClick('monthly')}  variant={activeButton === 'monthly' ? 'primary' : 'secondary'}>
                            Mensuel
                          </Button>
                      <ButtonGroup>
        {/* <Button onClick={() => handleButtonClick('Value 1')}>Button 1</Button>
        <Button onClick={() => handleButtonClick('Value 2')}>Button 2</Button>
        <Button onClick={() => handleButtonClick('Value 3')}>Button 3</Button> */}
      </ButtonGroup>


                    </ButtonGroup>

                      {/* <Button variant="secondary" size="sm">Voir plus</Button>   */}
              </Card.Header>


            {/* <FormsValueWidget
                  // title="Prospection test"
                  // value="26"
                  data={formsDaily}
                  // percentage={10.57}
                /> */}
                      
                      <Card.Body style={{ width: '100%', height: '250px' }}>
                          <ResponsiveContainer width="100%" height="100%" >
                                <AreaChart
                                  width={1000}
                                  height={300}
                                  data={formsDaily}
                                  // margin={{
                                  //   top: 5,
                                  //   right: 30,
                                  //   left: 20,
                                  //   bottom: 5,
                                  // }} 
                                >
                                  <XAxis dataKey="daty" />
                                  <YAxis />
                                  <YAxis yAxisId="right" orientation="right" />
                                  <Tooltip />
                                  <Legend  formatter={legendFormatter} />

                                  <Area type="monotone" dataKey="nbForms" stroke="#8884d8" fill="#acebfd" />
                                </AreaChart>
                      </ResponsiveContainer>
                      
                      {/* <div className="line-chart">
                        <Chart 
                          options={chartOptions} 
                          series={chartSeries} 
                          type="line" 
                          height={250} 
                        />
                      </div> */}
                      </Card.Body>
                </Card>
            </Col>
           </Row> 
          <Row>
            <Col xs={6} xl={5} className="mb-4">
                  {/* <PageVisitsTable /> */}
                  <Card border="light" className="shadow-sm">
                    <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                      <h5 className="mb-0">Recap par Projet</h5>
                      {/* <Button variant="secondary" size="sm">Voir plus</Button>   */}
                    </Card.Header>
                    <Card.Body>
                      <DataTable
                        columns={columnsForms}
                        data={formsData}
                        pagination
                        onRowClicked={changeForms}
                        conditionalRowStyles={conditionalRowStyles}
                        pointerOnHover
                        customStyles={customStyles}
                       />
                      </Card.Body>
                    </Card>
              </Col>  

                <Col xs={12} xl={7} className="mb-4">
                    {/* {usersOnline.length > 0  &&
                     <TeamMembersWidget data = {usersOnline}/>
                    } */}
                  <Card border="light" className="shadow-sm">
                    <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                      <h5 className="mb-0">Recap par Responsable</h5>
                      {/* <Button variant="secondary" size="sm">Voir plus</Button>   */}
                    </Card.Header>
                    <Card.Body>
                    <DataTable
                        // // title="User List"
                        columns={columnsLead}
                        data={formsLead}
                        pagination
                        onRowClicked={changeLead}
                        conditionalRowStyles={conditionalRowStylesLead}
                        pointerOnHover
                        customStyles={customStyles}
                />

                    </Card.Body>
                  </Card>
            </Col>
          </Row>
          </Col>
          <Col xl={4} className="mb-4 d-none d-sm-block">
            <Card border="light" className="shadow-sm">
            <Card.Header className="border-bottom border-light d-flex justify-content-between dashboard-table">
                      <h5 className="mb-0">Recap par Utilisateurs</h5>
                      {/* <Button variant="secondary" size="sm">Voir plus</Button>   */}
                </Card.Header>
            <Card.Body>
                <DataTable
                        // // title="User List"
                        columns={columnsUser}
                        data={usersOnline}
                        pagination
                        onRowClicked={changeUser}
                        conditionalRowStyles={conditionalRowStylesUser}
                        pointerOnHover
                        customStyles={customStyles}
                />

            </Card.Body>
        </Card>
                           

          </Col>
      </Row>
    </>
  );
};
