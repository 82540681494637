import React, { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('accessToken') || '');
  const history = useHistory();

  const login = async (email, pass) => {
    try {
      const res = await axios.post('/api/signin', { email, pass }); 
      setToken(res.data.token);
      localStorage.setItem('accessToken', res.data.token)
      localStorage.setItem('user', JSON.stringify(res.data.user))
      console.log('resultats tokent' + JSON.stringify(res))

      history.push(`/dashboard/overview`);

    } catch (err) {
      console.error(err);
      if(err.response && err.response.data.status == "failed") {
        swal({
          title: "Erreur",
          text: err.response.data.message,
          icon: "error",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
          return false
        });
      } else {
        swal({
          title: "Erreur",
          text: "Une erreure s'est produite.",
          icon: "error",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
          return false
        });
      }

      
     
    }
  };

  const googleLogin = async (userId,sessionID,token ) => {
    try {

      const res = await axios.post('/api/checkonlinestatus', { userId,sessionID,token }); 
      const data = res.data
      setToken(data.accessToken);
        localStorage.setItem('accessToken', data.accessToken)
        localStorage.setItem('user', JSON.stringify(data.user))
        history.push(`/dashboard/overview`);
      
    } catch (err) {
      console.error(err);
      if(err.response && err.response.data.status === "session_expired") {
        setToken(null);
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')

        swal({
          title: "Session expirée",
          text: "Votre session a expirée.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
        });
        history.push("/sign-in");
      }else {
        swal({
          title: "Erreur",
          text: "Une erreure s'est produite",
          icon: "error",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
        });
      }
    }
  };

  const logout = async (accessToken) => {
    console.log("accessToken", accessToken)
    try {
      const res = await axios.post('/api/signout', { accessToken }, 
        {headers: {
        'Authorization': `Bearer ${token}`
      }}); 
      const data = res.data
      setToken(null);
      // remove access token
      localStorage.removeItem('accessToken')
      localStorage.removeItem('user')
      
      history.push("/sign-in");

      
    } catch (err) {
      console.error(err);
      
      if(err.response && err.response.data.status === "session_expired") {
        setToken(null);
        localStorage.removeItem('accessToken')
        localStorage.removeItem('user')

        swal({
          title: "Session expirée",
          text: "Votre session a expirée.",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
        });
        history.push("/sign-in");
      }else {
        swal({
          title: "Erreur",
          text: "Une erreure s'est produite",
          icon: "error",
          buttons: true,
          dangerMode: true,
        })
        .then((result) => {
          console.log(result)
        });
      }
      
    }
  };

  return (
    <AuthContext.Provider value={{ token, login, logout, googleLogin }}>
      {children}
    </AuthContext.Provider>
  );
};
