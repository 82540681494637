
import React, { useEffect,useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'; 
import { Routes } from "../routes";


export default () => {
  const history = useHistory();
  const [inLoading, setInLoading] = useState(false)
  const routeParams  = useParams();
  const token = routeParams.token
  

  
  

  useEffect(() => {
    const checkTokenValidity = () => {
      
        if(token != null && token != "") {
          console.log("checking...")
          fetch('/api/checktokennewsletter', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({token}),
          }).then(
            response => response.json()
          ).then(
            data => {
              if (data.status === "success") {
               
              } else {
                console.log("data.error...")
                // Affichage d'un message d'erreur
                history.push("/#/");
              }
            }
          );
        }else {
          history.push("/page-not-found/404");
        }
      
    }
    checkTokenValidity();
  }, []);

  return (
    <main>
      <div>
            <Toaster   
              position="top-right"
              reverseOrder={false} />
          </div>
      <section className="bg-soft d-flex align-items-center my-5 mt-lg-8 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Presentation.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à la page d'accueil
              </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3 className="mb-4">La Newsletter FieldConnect</h3>
                <p>
                Merci de s'intéresser à FieldConnect, désormais vous seriez informé de toutes les mises à jour sur FieldConnect.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};


