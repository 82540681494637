import React, {useState,useContext, useEffect} from "react";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Card, Form, Button, InputGroup, Breadcrumb } from '@themesberg/react-bootstrap';
import Datetime from "react-datetime";
import { Routes } from "../routes";
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert';
import Select from 'react-select';

import AuthContext from "../context/AuthProvider";

// Add our stylesheets for the demo.
require('../scss/react-form-builder/application.scss');
import { useAuth } from './AuthContext';


const url = '/api/prospecting';
const saveUrl = '/api/prospecting';

export default () => {
  const history = useHistory()
  const [ userId, setUserId ] = useState();//useContext(AuthContext);
  const [defaultTeam, setDefaultTeam] = useState([])
  const [selectedTeam, setSelectedTeam] = useState([])
  const [teamList, setTeamList] = useState([])
  const [team, setTeam] = useState([])
  const [forceLocation, setForceLocation] = useState(true)
  // const [userCon, setUserCon] = useState();
  // const [getTeamUser, setGetTeamUser] = useState(null)
  // const [getAllTeam, setGetAllTeam] = useState(null)
  const { token } = useAuth();
  const userCon = JSON.parse(localStorage.getItem("user")) 
  
  // useEffect(() => {
  //   setUserId(userCon._id)
  // }, [userCon]);

const getTeamUser =  () => { 
    fetch(`/api/getUser/${userCon._id}`).then(
      response => response.json()
    ).then(
      response => {
        setTeamList(response[0].team)
      }
    )
};

const getAllTeam =  () => { 
      fetch(`/api/getTeam/${userCon._id}`).then(
        response => response.json()
      ).then(
        response => {
          const transformedData = response["teams"].map(team => ({
            value: team._id,
            label: team.name,
          }));
          setTeam(transformedData);
      }
    );
}

useEffect(() => {
    getTeamUser()
    getAllTeam()
  }, [userCon]);

const handleCreateForms = () => { 
  if(prospectingName == "") {
    swal({
      title: "ERREUR",
      text: "Le nom du formulaire ne peut pas être vide.",
      icon: "error",
      buttons: true,
      dangerMode: true,
    })
    .then((result) => {
      console.log(result)
    });
  } else {
    let options = {
      note: prospectingNote,
      teams: selectedTeam,
      forceLocation:forceLocation
    }
    console.log(options)
    const params = {
      userId : userCon._id,
      prospectingName: prospectingName,
      options: options
    }

    fetch("/api/createProject/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({forms_info: params  }),
    }).then(
        response => response.json()
    ).then(
      // data => console.log(data)
        data => {

          if(data.status === "success") {
            console.log('my prospecting fata' +data['prospecting']._id)
           history.push({pathname: `/create-forms/${data['prospecting']._id}`,state: {params}});
          
          }else if(data.status === "session_expired") {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('user')
            swal({
              title: "Session expirée",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
            this.props.history.push("/sign-in");
          }else {
            swal({
              title: "Erreur",
              text: data.message,
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            .then((result) => {
              console.log(result)
            });
          }
          

          
        }
        
    )
  }
}


  
const handleChangeTeam = (selectedOptions) => {
  console.log('selected values'+JSON.stringify(selectedOptions) )
  // team
  let t_obj = selectedOptions === null ? [] : selectedOptions.map(option => option.value)
  console.log(t_obj)
  setTeamList(t_obj)
  setSelectedTeam(t_obj)
};


  const [prospectingName, setProspectingName] = useState("");
  const [prospectingNote, setProspectingNote] = useState("");
  const handleNameChange = (event) => {
    setProspectingName(event.target.value);
  };
  const handleNoteChange = (event) => {
    setProspectingNote(event.target.value);
  };

  const handleForceLocationChange = (event) => {
    setForceLocation(!forceLocation);
    
  };

  console.log("defaultTeam", defaultTeam)


  return ( 
    <>
    <div style={{  marginTop: '-20px'}} className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/forms-project' }}>Projet</Breadcrumb.Item>
            <Breadcrumb.Item active>Nouveau projet</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        
      </div>


  
  <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">Information Générale</h5>
        <Form>
          <Row>
            <Col  className="mb-3">
              <Form.Group id="firstName">
                <Form.Label>Nom du projet</Form.Label>
                <Form.Control onChange={handleNameChange} required type="text" placeholder="Saisissez le nom du projet" />
              </Form.Group>
            </Col>
            
          </Row>
          
          <Row>
            <Col className="mb-3">
              <Form.Group id="note">
                <Form.Label>Description</Form.Label>
                <Form.Control onChange={handleNoteChange} required  as="textarea" rows={3} placeholder="Description du projet"  />
              </Form.Group>
            </Col>
            
          </Row>

          <Row>
            <Form.Group>
                <Form.Label>Equipe</Form.Label>
                           <Select
                              
                              isMulti
                              options={team}
                              onChange={handleChangeTeam}
                          />
                    {/* {defaultTeam.length > 0 ? (
                      <div>
                         <Select
                              
                              isMulti
                              options={team}
                              onChange={handleChangeTeam}
                          />
                      </div>
                    ) : (
                      <Select
                           
                            isMulti
                            options={team}
                            onChange={handleChangeTeam}
                         />
                    )} */}

                </Form.Group>
          </Row>


          <Row>
            <Col className="mb-3">
              <Form.Group style={{marginTop:30}}>
                  <Form.Check // prettier-ignore
                    type="switch"
                    id="custom-switch"
                    label="Forcer la coordonnées GPS"
                    name="leader"
                    checked={forceLocation}
                    onChange={handleForceLocationChange}
                  />
              </Form.Group>
            </Col>
            
          </Row>

         
          <div className="mt-3">
            <Button  onClick={() => handleCreateForms()} variant="primary" type="button">Continuer</Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
    
    </>
  );
};
