
import React, { useState, useEffect, useContext }  from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt, } from "@fortawesome/free-solid-svg-icons";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup, Spinner,Alert, Image } from '@themesberg/react-bootstrap';
import { useHistory ,Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast'; 
import AuthContext from "../context/AuthProvider";
import jwt_decode from 'jwt-decode';
import { Routes } from "../routes";
import BgImage from "../assets/img/illustrations/signin.svg";
import { useLocation } from 'react-router-dom';
import NotFoundImage from "../assets/img/homelogo.png";
import { AuthProvider, useAuth } from './AuthContext';


export default () => {
  const { userId, setUserId} = useContext(AuthContext);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory()
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formValidation, setFormValidation] = useState(false);
  const [inLoading, setInLoading] = useState(false);
  const { login } = useAuth();
  const token = localStorage.getItem("accessToken")
  // Récupérez les notifications
  const successSignup = params.get('successSignup');
  const forgotPassword = params.get('forgotPassword');
  const verifiedUser = params.get('verifiedUser');

  useEffect(() => {
    

    const checkUserConnected = () => {
      try {
        const decodedToken = jwt_decode(token); //
        if(token != null && decodedToken.id != null) {
          history.push("/dashboard/overview");
        }
      }catch(err) { 
        console.log(err)
      }
    }
    checkUserConnected();
  }, []);

  const googleAuth = () => {
    window.open(
      `${process.env.API_HOST}/api/google`,
      "_self"
    );
  }
 
  const handleSubmit_ = async (e) => {
    e.preventDefault();
    setInLoading(true)
    // // const { email, password } = e.target.elements;
    let res = await login(email, password)
    setInLoading(false)
    
  };


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
          <div>
            <Toaster   
              position="top-right"
              reverseOrder={false} />
          </div>
        <Container> 
          <p className="text-center">
            <Card.Link as={Link} to={Routes.Presentation.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour a la page daccueil
            </Card.Link>
          </p>

          {/* Alert  verified user*/}
          {
            verifiedUser != null && verifiedUser == 1 ? (
              <Row className="justify-content-center form-bg-image">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className=" p-4 p-lg-2 w-100 fmxw-500">
                      <Alert  variant="success">
                      Votre compte a été vérifié avec succès, vous pouvez maintenant vous connecter.
                      </Alert>
                  </div>
                    
                    
                </Col>
              </Row>
            ) : (
              <div></div>
            )
          }


          {/* Alert Signup success */}
          {
            successSignup != null && successSignup == 1 ? (
              <Row className="justify-content-center form-bg-image">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className=" p-4 p-lg-2 w-100 fmxw-500">
                      <Alert  variant="success">
                      Un message avec un lien de confirmation vous a été envoyé par mail. Veuillez suivre ce lien pour activer votre compte.
                      </Alert>
                  </div>
                    
                    
                </Col>
              </Row>
            ) : (
              <div></div>
            )
          }



          {/* Alert Forgot password  */}
          {
            forgotPassword != null && forgotPassword == 1 ? (
              <Row className="justify-content-center form-bg-image">
                <Col xs={12} className="d-flex align-items-center justify-content-center">
                  <div className=" p-4 p-lg-2 w-100 fmxw-500">
                      <Alert  variant="success">
                      Les instructions pour réinitialiser votre mot de passe vous ont été envoyées.
                      </Alert>
                  </div>
                    
                    
                </Col>
              </Row>
            ) : (
              <div></div>
            )
          }

          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  
                  <Image src={NotFoundImage} className="img-fluid w-75" />
                  <h5 style={{marginTop:10}} className="mb-0">Se connecter à votre compte</h5>
                </div>
                <Form  onSubmit={handleSubmit_} className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Email</Form.Label>
                    <InputGroup >
                      <InputGroup.Text >
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control  required type="text" isInvalid={formValidation}   placeholder="example@company.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                      <Form.Control.Feedback type="invalid">
                        {formValidation}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.Group id="pass" className="mb-4">
                      <Form.Label>Mot de passe</Form.Label>
                      <InputGroup >
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faUnlockAlt} />
                        </InputGroup.Text>
                        <Form.Control isInvalid={formValidation} required type="password" value={password} onChange={(e) => setPassword(e.target.value)}   placeholder="Password" />
                        <Form.Control.Feedback type="invalid">
                          {formValidation}
                        </Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Form.Check type="checkbox">
                        <FormCheck.Input id="defaultCheck5" className="me-2" />
                        <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Se souvenir</FormCheck.Label>
                      </Form.Check>
                      <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Mot de passe oublie?</Card.Link>
                    </div>
                  </Form.Group>
                  <Button    variant="primary" type="submit" className="w-100">
                    {
                      inLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Se connecter"
                      )
                    }
                  </Button>
                </Form>

                <div className="mt-3 mb-4 text-center">
                  <span className="fw-normal">Ou</span>
                </div>
                <div className="d-flex justify-content-center my-4">
                 
                  <Button onClick={googleAuth} variant="outline-light" className="btn btn-pill text-facebook me-2">
                    <FontAwesomeIcon icon={faGoogle} /> Se connecter avec Google
                  </Button>
                  
                 
                </div>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                  Pas encore de compte ?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {`  Enregistrez-vous  `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
