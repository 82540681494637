import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.fullscreen';
import 'leaflet.fullscreen/Control.FullScreen.css';

const MapAdvanced = (props) => {
  const mapRef = useRef(null);
  const markersRef = useRef(null); // Use ref to store markers

  useEffect(() => {
    const map = L.map(mapRef.current).setView([-18.8944862,47.505461], 6);
    // map.addControl(new L.Control.fullscreen());

    // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(map);

    const osmLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
    // const osmHumanitarianLayer = L.tileLayer('https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png');
    const esriImageryLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}');
    // const esriTopoLayer = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}');


    osmLayer.addTo(map);

    // Créer un groupe de couches de base
    const baseLayers = {
      "OpenStreetMap": osmLayer,
      // "OpenStreetMap Humanitarian": osmHumanitarianLayer,
      "Esri Imagery": esriImageryLayer
      // "Esri Topographic": esriTopoLayer
    };

    // Ajouter le contrôle des couches
    L.control.layers(baseLayers).addTo(map);
    L.control.fullscreen().addTo(map);


    markersRef.current = L.markerClusterGroup();
    map.addLayer(markersRef.current);

    const handleZoomEnd = () => {
      const zoomLevel = map.getZoom();
      const bounds = map.getBounds();
      // console.log('Zoom level:', zoomLevel);
      // console.log('Map bounds:', bounds);

      const visibleMarkers = [];
      markersRef.current.eachLayer((layer) => {
        if (bounds.contains(layer.getLatLng())) {
          visibleMarkers.push(layer.getLatLng());
        }
      });

      // console.log('Visible markers:', visibleMarkers);
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
      map.remove();
    };
  }, []);

  useEffect(() => {
    if (markersRef.current) {
      markersRef.current.clearLayers();

      props.marker["forms"].forEach(m => {
        const marker = L.marker([m.latitude, m.longitude]);

        const popupContent = `
                <div>
                  <strong>Projet :</strong> ${ m.prospectingId.name  }<br>
                  <strong>Utilisateur :</strong> ${m.user  ? m.user.name  : ''}<br>
                  <strong>DateStart :</strong> ${m.startDate}
                </div>
            `;


        marker.bindPopup(popupContent);

        markersRef.current.addLayer(marker);
      });
    }
  }, [props.marker]);

  return <div id="map" ref={mapRef} style={{ height: '75%', width: '100%' }}></div>;
};

export default MapAdvanced;
