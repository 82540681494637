import React, {useState, useContext, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import FormBuilder, { Registry } from '../components/react-form-builder/index';
import DemoBar from './DemoBar';
import * as variables from '../../variables';
import {  Breadcrumb, Button } from '@themesberg/react-bootstrap';
import { useHistory, useLocation ,useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import AuthContext from "../context/AuthProvider";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert';



// Add our stylesheets for the demo.
require('../scss/react-form-builder/application.scss');
import { useAuth } from './AuthContext';


const url = '/api/prospectings';
const saveUrl = '/api/prospecting';

export default () => {
  const [ userId, setUserId ] = useState();//useContext(AuthContext);
  const history = useHistory()
  const location = useLocation();
  const {  params } = location.state || {};
  console.log('params', params)
  const userCon = JSON.parse(localStorage.getItem("user")) 
  const { projectId } = useParams();
  const { token } = useAuth();
  const [forms, setForms] = useState([])
//   Registry.register('MyInput', MyInput);
// Registry.register('TestComponent', TestComponent);
console.log('my projet id'+projectId)
const items = [{
    key: 'Header',
  },{
    key: 'Label',
  },{
    key: 'Paragraph',
  },{
    key: 'TextInput',
  },{
    key: 'EmailInput', 
  },{
    key: 'PhoneNumber',
  },{
    key: 'NumberInput',
  },{
    key: 'Dropdown',
  }, {
    key: 'TextArea',
  }, {
    key: 'RadioButtons',
  }, {
    key: 'Checkboxes',
  }, {
    key: 'Image',
  },
  {
    key: 'DatePicker',
  },
  {
    key: 'Tags',
  },
  {
    key: 'HyperLink',
  },
  {
    key: 'Camera',
  },
  { 
    key: 'FileUpload'
  },
  {
    key: 'Signature',
  },
  {
    key: 'Rating',
  },
  {
    key: 'Range',
  },
  {
    key: 'FieldSet',
  },
  {
    key: 'TwoColumnRow',
  }
];


useEffect(() => {
  const userCon =  JSON.parse(localStorage.getItem("user"))

    fetch("/api/getFormsName/", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({formId : projectId,userId: userCon._id }),
    }).then(
        response => response.json()
    ).then(
        data => {
         
          setForms({
            idProject : data['forms'][0]._id,
            userId:userCon._id,
            name : data['forms'][0].name
          })

          console.log('data forms' + JSON.stringify(data) )
        }
    )}, [projectId])

  // const forms_info = {
  //   // name: params.prospectingName ,
  //   // options: params.options ,
  //   idProject : projectId,
  //   userId:userCon._id
  // }

  // console.log(forms_info)
 

  return (
    <>
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            {/* <Breadcrumb.Item>Projet</Breadcrumb.Item> */}
            <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/forms-project' }}>Projet</Breadcrumb.Item>
            <Breadcrumb.Item active>{forms.name || ''}</Breadcrumb.Item>
          </Breadcrumb>
          <h4>Créez votre formulaire</h4>
          {/* <p className="mb-0">Gérer votre prospection selon votre besoin.</p> */}
        </div>
        
      </div>
    {
      userCon !== undefined && forms.name != '' ? (
        <>
          <DemoBar variables={variables} forms_info={forms} />

          <FormBuilder.ReactFormBuilder
            variables={variables}
            saveUrl={saveUrl}
            locale='fr'
            saveAlways={false}
            forms_info={forms} //custom field
            toolbarItems={items}

          />
        </>
      ) : (
        <p>Chargement...</p>
      )
    }
    </>
  );
};