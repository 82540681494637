
import React, {useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Card, Button, Container, InputGroup, Spinner } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { Routes } from "../routes";


export default () => {
  const history = useHistory();
  const [inLoading, setInLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailValidation, setEmailValidation] = useState(null)

  const recoverPassword = () => {
    if(email != "") {
      setEmailValidation(null)
      setInLoading(true)
      console.log(email)
      // Envoyer les données au backend
      fetch("/api/forgotpassword", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      }).then(
            
        response => response.json()
      ).then(
        
          data => {
            setInLoading(false)
            history.push("/sign-in?forgotPassword=1");
          }
        )


      
    } else {
      setEmailValidation("L'adresse email ne peut pas être vide.")
    }
  }

  return (
    <main>
      <section className="vh-lg-100 mt-4 mt-lg-0 bg-soft d-flex align-items-center">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Retour à la page de connexion
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Mot de passe oublié ?</h3>
                <p className="mb-4">Saisissez votre adresse email et nous vous enverrons un lien pour réinitialiser votre mot de passe !</p>
                <Form>
                  <div className="mb-4">
                    <Form.Label htmlFor="email">Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control isInvalid={emailValidation}  required autoFocus type="email" value={email} onChange={(e) => setEmail(e.target.value)}  />
                      <Form.Control.Feedback type="invalid">
                        {emailValidation}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </div>
                  <Button variant="primary" onClick={() => recoverPassword()} type="button" className="w-100">
                    {
                      inLoading ? (
                        <Spinner animation="border" />
                      ) : (
                        "Récupérer le mot de passe"
                      )
                    }
                  
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
