import React, {useState, useEffect,Component}from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome,faPlus,faEllipsisH,faEdit,faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Modal ,Form ,  Breadcrumb,Button,Col, Row , Nav, Card, Image, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import { Routes } from "../routes";
import { Link,useHistory } from 'react-router-dom';
import Swal from 'sweetalert';
import ReactDOM from 'react-dom';
import { faLastfmSquare } from "@fortawesome/free-brands-svg-icons";
// import { CreateTags } from "./CreateTags"; 
import { useAuth } from './AuthContext';

import { CreateTeam } from "../components/TeamManagement/CreateTeam"; 

// import { UpdateTeam } from "../components/TeamManagement/UpdateTeam"; 

import toast, { Toaster } from 'react-hot-toast'; 
import DataTable from 'react-data-table-component';

export default () => {

const [teams, setTeams] = useState([])

const [company, setCompany]  =  useState() //
const userCon = JSON.parse(localStorage.getItem("user")) 
const [role, setRole]  = useState()

const history = useHistory()


const { token } = useAuth();

const fetchTeams = () => {
    
  fetch(`/api/getAllTeams/${userCon._id}`).then(
    response => response.json()
  ).then(
      data => setTeams(data["teams"])
  )
}

const fetchUserRole = () => {
  fetch(`/api/getUser/${userCon._id}`).then(
      response => response.json()
    ).then(
      response => {
        setRole(response[0].role);
      }
    )
}


useEffect( () => {
    fetchTeams()
    fetchUserRole()
}, [])

    const [showModal, setShowModal] = useState(false);
    const [showModif, setShowModif] = useState(false);

    const [formData, setFormData] = useState({
      teamId : '',
      teamName: '',
      desc: ''
    });
    

    const handleShowModal = () => setShowModal(true);

    const handleCloseModal = () => setShowModal(false)

    const handleCreateTeam = (newTeam) => {

    fetch('/api/createTeam', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json','Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({name:newTeam.name,desc:newTeam.desc,company:userCon.company,owner: userCon.name}),
      }).then((response) => response.json())
        .then(
          data => { 
              fetchTeams(userCon);
              if (data.status ==  'success') {

                history.push(`/add-user-team/${data.res._id}`);

                toast.success('Création Equipe réussie', {
                  style: {
                    border: '1px solid #713200',
                    padding: '16px',
                }});
                n_t
              } else {
                toast.error(data['message'], {
                  style: {
                    border: '1px solid #713200',
                    padding: '16px',
                  }});
              }
          }
        )
    };


     const handleInputChange = (e) => {
      const { name, value } = e.target;
      console.log('vaeu8'+value)

      setFormData({ ...formData, [name]: value });
    }

    const handleEdit = (_id,name,desc) => {
      console.log('fdevfe'+desc)
      setFormData({ teamId : _id, teamName: name,desc: desc});
      setShowModif(true)
    }
     
    const handleCloseModif = () => {
      setShowModif(false)
    }
  
    const handleSubmitModif = async () => {
      try {
        const response = await fetch('/api/updateTeam', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' ,'Authorization': `Bearer ${token}`},

          body: JSON.stringify({ id: formData.teamId, name: formData.teamName, desc: formData.desc }),
        });
        const data = await response.json();
        fetchTeams(userCon);
        if (data.status === 'success') {
          
          toast.success('Modification equipe réussie', {
            // duration: 50000, 
            style: {
              border: '1px solid #713200',
              padding: '16px',
            }});
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error('Error updating team:', error);
        toast.error('Une erreur est survenue');
      }
      handleCloseModif()
    }
    const handleDelete = (id,name,company) => {
      swal({
        title: "Confirmation",
        text: "Voulez-vous supprimer définitivement cet équipe ?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((res) => {
        if (res) {
          fetch('/api/deleteTeam', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({id,name,company}),
          }).then(response => response.json()).then(
            data => {
              if(data.status == 'success'){
                  const updatedTeam = teams.filter((team) => team._id !== id);
                  setTeams(updatedTeam);
                 toast.success('Suppression Equipe réussie', {
                  style: {
                    border: '1px solid #713200',
                    padding: '16px',
                  }});
              }else{
                toast.success('Suppression Equipe non réussie', {
                  style: {
                    border: '1px solid #713200',
                    padding: '16px',
                  }})
              }
            }
          )
        } 
      });
    }

    const columns = [
      {
        name: 'Nom',
        selector: row => row.name,
        width: '200px'
      },
      { name: 'Nombres utilisateurs', selector: row => row.user_list.length, sortable: true },
      { name: 'Description', selector: row => row.desc ? row.desc : "--"},
      { name: 'Créer par', selector:row => row.owner ? row.owner : "--", sortable: true },
      { name: 'Date Création', selector: row => row.creation_date, sortable: true },
      { name: 'Action',  
      selector: row => (
        role === 'ADMIN' ? ( <>
                <Link to={{ pathname: `/add-user-team/${row._id}`}}title="Ajouter/retirer des utilisateurs">
                    <FontAwesomeIcon  className="me-2" icon={faPlus} /> 
                </Link>
               <Link onClick={() => handleEdit(row._id,row.name,row.desc)} title="Modifier">
                      <FontAwesomeIcon  icon={faEdit} className="me-2" />
                </Link> 
               <Link onClick={() => handleDelete(row._id,row.name,company)} title="Supprimer">
                    <FontAwesomeIcon  className="text-danger me-2" icon={faTrashAlt} /> 
               </Link>
          </>):<></>
      ), sortable: true }
    ];

    
    const customStyles = {
      headCells: {
        style: {
          fontWeight: 'bold',
          backgroundColor: '#1f2334', 
          color : 'white'// You can set your desired background color here
        },
      },
    };
   console.log(teams)
  return(
    <>
      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} />FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item active>Equipe</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-between align-items-center">
        <Col xs={8} md={6} lg={3} xl={4}>
          {
            role === 'ADMIN' ? (<Button variant="primary" size="sm" onClick={() => handleShowModal(true)}><FontAwesomeIcon icon={faPlus} className="me-2" />Créer</Button>):<></>
          } 
        </Col>
        </Row>
      </div>
      <div>
        <Toaster   
          position="top-right"
          reverseOrder={false} />
      </div>
     <CreateTeam show={showModal} handleClose = {handleCloseModal} onCreateTeam ={handleCreateTeam}  />

    {/* {dataForModal && (
      <UpdateTeam data={dataForModal} onUpdate ={handleUpdate} handleClose ={() => setDataForModal(null)} />
      )} */}
     

     {/* striped */}
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
      <DataTable
            // title="User List"
            columns={columns}
            data={teams}
            pagination
            // paginationResetDefaultPage={resetPaginationToggle}
            subHeader
            customStyles={customStyles}
            // subHeaderComponent={subHeaderComponent}
            // actions={actionsMemo}
    />
      </Card.Body>
    </Card>
    <Toaster   
          position="top-right"
          reverseOrder={true}
         />
           <Modal as={Modal.Dialog} centered show={showModif} onHide={handleCloseModif} backdrop="static" > 
        <Modal.Header>
          <Modal.Title className="h6">Modifier l' Equipe</Modal.Title>
          <Button variant="close" aria-label="Close" onClick={handleCloseModif} />
        </Modal.Header>
        <Modal.Body>
          <Form >
              <Row>
                  <Form.Group>
                    <Form.Label>Nom</Form.Label>
                    <Form.Control  name="teamName" onChange={handleInputChange} value= {formData.teamName}  required type="text"/>
                  </Form.Group>
              </Row>
              
              <Row>
                  <Form.Group>
                    <Form.Label>Descritpion</Form.Label>
                    <Form.Control  name="desc"   onChange={handleInputChange} value= {formData.desc} required type="textarea"/>
                  </Form.Group>
              </Row>
          </Form>
        </Modal.Body>
          <Modal.Footer>
            <Button variant="link" className="text-gray ms-auto" onClick={handleCloseModif}>
              Annuler
            </Button>
            <Button variant="secondary" type="submit" onClick={handleSubmitModif} >
              Modifier
            </Button>     
          </Modal.Footer>
      </Modal>


    </>
  );
};