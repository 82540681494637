import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from "react-router-dom";
import { AuthProvider } from './src/context/AuthProvider';
// eslint-disable-next-line no-unused-vars

// core styles
import "./src/scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

import HomePage from "./src/pages/HomePage";
import ScrollToTop from "./src/components/ScrollToTop";




const TestComponent = () => <h2>Hello</h2>;

const MyInput = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props;
  return (
    <>
      <label style={{ marginRight: '1rem' }}><b>{ props.data.label }</b></label>
      <input ref={ref} name={name} defaultValue={defaultValue} disabled={disabled} />;
    </>
  );
});



const App = () => (
  
  <HashRouter>
    <ScrollToTop />
    <HomePage />
  </HashRouter>
  );

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <DemoBar variables={variables} />,
//   document.getElementById('demo-bar'),
// );
