import React, {useState,useContext, useEffect} from "react";
import {  faHome } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Card, Form, Button, Breadcrumb } from '@themesberg/react-bootstrap';
import {Link,useHistory, useLocation,useParams } from 'react-router-dom';
import Select from 'react-select';
import toast, { Toaster } from 'react-hot-toast';   
import { ChoosePhotoWidget, ProfileCardWidget } from "../Widgets";
// import axios from 'axios';
import { useAuth } from '../../pages/AuthContext';


export default () => {

  const history  = useHistory();
  const location = useLocation();
  const { user } = location.state || {};
  const [teamList, setTeamList] = useState([])
  const [formValues, setFormValues] = useState({})
  const { userId } = useParams();
  const [team, setTeam] = useState([])
  
  const [defaultTeam, setDefaultTeam] = useState([])
  const [leadName, setLeadName] = useState('')
    
  const [reqMail, setReqMail] = useState();
  const { token } = useAuth();

  const [myrole, setMyrole] = useState(false);
  const userCon =  JSON.parse(localStorage.getItem("user"))
  const [leadList, setLeadList] = useState([])

  useEffect(() => {
    const userCon =  JSON.parse(localStorage.getItem("user"))
      fetch(`/api/getUser/${userCon._id}`).then(
      response => response.json()
    ).then(
      response => {
        setMyrole(response[0].role)
      }
    )}, [])

  useEffect(() => {
      fetch(`/api/getLead/${userCon._id}`).then(
      response => response.json()
  ).then(
    response => {
      const leadList = response['lead'].map((user) => ({
        value: user._id,
        label: user.name + ' ' + user.firstName,
      }));
      setLeadList(leadList) 
    }
)}, [])

useEffect(() => {

        const getTeamUser = async () => { 
            await  fetch(`/api/getUser/${userId}`).then(
              response => response.json()
            ).then(
              response => {
                  const transformedData = response[0].team.map(team => ({
                    value: team._id,
                    label: team.name,
                  }));
                  
                  // console.log('response'+ JSON.stringify(response) )
                  setDefaultTeam(transformedData);
                  setFormValues({
                    name: response[0].name || '',
                    firstName: response[0].firstName || '',
                    email: response[0].email || '',
                    phoneNumber: response[0].phoneNumber || '',
                    gender : response[0].gender || '' ,
                    role : response[0].role || '' ,
                    plateforme :response[0].plateforme || '',
                    team: response[0].team || '',
                    leader: response[0].leader ,
                    image: response[0].image,
                    company: response[0].company.name , 
                    // leadName: response[0].leadId.name + ' ' + response[0].leadId.firstName ,
                    leadId: response[0].leadId ? response[0].leadId._id:''
                  });
                  
                  const check_mail = response[0].plateforme =='mail'?true:false
                  setReqMail(check_mail)
                  setTeamList(response[0].team)
                  // console.log('list team user'+JSON.stringify(formValues))
              }
            )
        };

        const getAllTeam = async () => { 
          await   fetch(`/api/getTeam/${userCon._id}`).then(
                  response => response.json()
              ).then(
                response => {
                  const transformedData = response["teams"].map(team => ({
                    value: team._id,
                    label: team.name,
                  }));
                  setTeam(transformedData);
                  // console.log('list all team'+JSON.stringify(response))
              }
                  // data => setTeam(data["teams"])
              );
        }
        getTeamUser();
        getAllTeam();
  }, [userId]);


const handleChangeTeam = (selectedOptions) => {
  let t_obj  = selectedOptions === null ?[]:selectedOptions.map(option => option.value)
  setTeamList(t_obj)
};


  const handleInputChange = (e) => {  
    const name  = e.target.name;
    let value = name === 'leader'? e.target.checked:e.target.value
    if(name  ==  'role' && value != 'UTILISATEUR SIMPLE') {
      setFormValues((prevValues) => ({
        ...prevValues,
        'leadId': '',
      }));
    } 
       
    if(name  ==  'plateforme') {
      const ref       =  e.target.value === 'mobile'? false : true
      setReqMail(ref)
    } 

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log('forma vale'+ JSON.stringify(formValues) )
  };

  const handleSubmit =  (e) => {
    e.preventDefault();
      try {
        const leadId = formValues.leadId != "" ? formValues.leadId : null
          fetch('/api/updateUser', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(
              {id :userId,name:formValues.name,firstName:formValues.firstName,  gender : formValues.gender,phoneNumber:formValues.phoneNumber,
                plateforme:formValues.plateforme,leader:formValues.leader,team : teamList,leadId,email:formValues.email,role:formValues.role}),
        }).then(
          response => response.json()
        ).
        then(
          data => {
            if (data.status == 'success') {
              toast.success('Modification réussie', {
                style: {
                  border: '1px solid #713200',
                  padding: '16px',
                }});
              history.push("/user");
            } else {
              toast.error(data['message'], {
                style: {
                  border: '1px solid #713200',
                  padding: '16px',
                }});
              console.error(data.message);
            }
          }
        )
    } catch (error) {
      console.error('Erreur lors de la soumission du formulaire :', error);
    }

     
  };

  return ( 
    <>
    <div style={{  marginTop: '-20px'}} className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <Breadcrumb className="d-none d-md-inline-block" listProps={{ className: "breadcrumb-dark breadcrumb-transparent" }}>
            <Breadcrumb.Item><FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
            <Breadcrumb.Item>FieldConnect</Breadcrumb.Item>
            <Breadcrumb.Item  linkAs={Link} linkProps={{ to: '/user' }}>Utilisateurs</Breadcrumb.Item>
            <Breadcrumb.Item active>{formValues.name + ' '+ formValues.firstName }</Breadcrumb.Item>
          </Breadcrumb>
          <h4></h4>
          {/* <p className="mb-0">Gérer votre prospection selon votre besoin.</p> */}
        </div>
        
      </div>
      <div>
  {/* <Container className="d-flex align-items-center">        */}
  <Row>
        <Col xs={12} xl={8}>
        <Card border="light" className="bg-white shadow-sm mb-4">
        <Card.Body>
          <h5 className="mb-4">Modifier l'utilisateur</h5>
          <Form onSubmit={handleSubmit}>
            <Row>
                < Col className="mb-3" md={4} >
                  <Form.Group id="plate">
                      <Form.Label>Plateforme</Form.Label>
                      <Form.Select value={formValues.plateforme}  name="plateforme"  onChange={handleInputChange}> 
                            <option value="mobile">Mobile</option>
                            <option value="web">Web</option>
                            <option value="both">Les deux</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col md={6} className="mb-3" >
                  <Form.Group id="name">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control  name="name" value={formValues.name} onChange={handleInputChange}  required type="text" />
                  </Form.Group>
                </Col>
                <Col md={6} className="mb-3">
                  <Form.Group id="firstname">
                    <Form.Label>Prénom</Form.Label>
                    <Form.Control  name="firstName" value={formValues.firstName} onChange={handleInputChange}   type="text" rows={3}  />
                  </Form.Group>
                </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="email">
                  <Form.Label>Mail</Form.Label>
                  <Form.Control name="email"  required={reqMail} value={formValues.email}  onChange={handleInputChange} type="text" />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="phoneNumber">
                  <Form.Label>Numéro Téléphone</Form.Label>
                  <Form.Control name="phoneNumber" required={!reqMail} value={formValues.phoneNumber} onChange={handleInputChange} type="text" rows={3}  />
                </Form.Group>
              </Col>
            </Row>  

           <Row>
            <Col className="mb-3"  md={6}> 
                <Form.Group id="note">
                <Form.Label>Genre</Form.Label>
                <Form.Select
                     value={formValues.gender} name="gender"
                    onChange={handleInputChange}> 
                    <option value=""></option>
                    <option value="Feminin">Féminin</option>
                    <option value="Masculin">Masculin</option>
                </Form.Select>
                </Form.Group>
            </Col>
            <Col className="mb-3"  md={6}> 
                <Form.Group id="note">
                    <Form.Label>Rôle</Form.Label>
                    <Form.Select
                            value={formValues.role}
                            name= "role"
                            onChange={handleInputChange}> 
                           {myrole != 'ADMIN' ?(
                            <option value={formValues.role}>{formValues.role}</option>
                           ):(
                            <>
                                  <option value="ADMIN">ADMIN</option>
                                  <option value="RESPONSABLE">RESPONSABLE</option>
                                  <option value="UTILISATEUR SIMPLE">UTILISATEUR SIMPLE</option>
                           </>) 
                          } 
                            
                    </Form.Select>
                </Form.Group>
            </Col>
        </Row>
      
          {/* <Row> */}
              {/* <Col className="mb-3" md={1}>
                  <Form.Group>
                      <Form.Check // prettier-ignore
                        type="switch"
                        id="custom-switch"
                        label="Responsable"
                        name="leader"
                       // checked = True //{formValues.leader}
                        onChange={handleInputChange}
                      />
                  </Form.Group>
                </Col> */}
          {/* </Row> */}
            <Row>
              <Col className="mb-3" md={6}>
                <Form.Group>
                <Form.Label>Equipe</Form.Label>
                    {defaultTeam.length > 0 ? (
                      <div>
                         <Select
                              defaultValue={defaultTeam}
                              isMulti
                              options={team}
                              onChange={handleChangeTeam}
                          />
                      </div>
                    ) : (
                      <Select
                            defaultValue={defaultTeam}
                            isMulti
                            options={team}
                            onChange={handleChangeTeam}
                         />
                    )}
                </Form.Group>
              </Col>

              <Col className="mb-3" md={6}> 
              <Form.Group id="plate">
                  <Form.Label>Nom Responsable</Form.Label>
                  <Form.Select name = 'leadId' value = {formValues.leadId} 
                        onChange={handleInputChange} disabled = {formValues.role == 'UTILISATEUR SIMPLE'? false : true }> 
                        <option value = '' ></option>
                        {leadList &&
                          leadList.map(l => <option value = {l.value} >{l.label}</option>)
                        }
                </Form.Select>
              </Form.Group>
            </Col>  

            </Row>                  
          

          <div className="mt-3">
            <Button variant="primary" type="submit">Modifier</Button>
          </div>

            </Form>
          </Card.Body>
          </Card>
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              <ProfileCardWidget data = {formValues}  />
            </Col>
            <Col xs={12}>
              {/* <ChoosePhotoWidget
                title="Modifier la photo de profil"
                photo={formValues.image}
              /> */}
            </Col>
          </Row>
        </Col>
    </Row>  


      
   
    {/* </Container> */}
    </div>
    <div>
        <Toaster   
          position="top-right"
          reverseOrder={true} 
          
        />
      </div>
    </>
  );
};
