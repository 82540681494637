import { useReactTable } from '@tanstack/react-table';
import React, {useState,useEffect,useContext} from "react";
import { Modal, Col, Row, Nav, Card, Image, Button, Table, Dropdown, ProgressBar, Pagination, ButtonGroup } from '@themesberg/react-bootstrap';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import {Loading} from '../components/Loading/Loading';
// import 'react-loading-skeleton/dist/skeleton.css';

// const imagePath = ``;

// import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useAuth } from '../pages/AuthContext';

export const FormsGalerie = ({formId}) => {
  const [showModal, setShowModal] = useState(false); 
  const [modalContent, setModalContent] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const { token } = useAuth();


useEffect( ()=> {
    fetchDataPhoto()
  }, [formId,page])
  
  const fetchDataPhoto = async  () => {
    setLoading(true);
    try {
        await  fetch('/api/getFormsPhoto', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({formId, page}),
          }).then(
            response => response.json()
          ).then(
              data => {
                  setImages((prevImages) => [...prevImages, ...data.images])
                  setHasMore(data.hasMore);
              }
          )
      } catch (error) {
      console.error("Error fetching images:", error);
    } finally {
      setLoading(false);
    }
  }

  const fetchMoreData = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleShowModal = (image) => {
    setModalContent(image);
    setShowModal(true); 
};

const handleImageLoad = (index) => {
  setLoadedImages((prevLoaded) => ({ ...prevLoaded, [index]: true }));
};
return(
 <>
    {
      isLoading ? <Loading /> : <></>
    }
    <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="pt-0">
          <InfiniteScroll
                dataLength={images.length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={<p>End of Images</p>}>

                <div className="image-gallery">
                      {images.map((image, index) => (
                          <div key={index} className="image-container">
                            <img
                              onClick={() => handleShowModal(image)}
                              src={`https://api.e-fieldconnect.com/uploads/items/imageItem_${image}.jpg`} 
                              alt={`image-${index}`}
                              onLoad={() => handleImageLoad(index)}
                              style={{ display: loadedImages[index] ? 'block' : 'none' }}
                            />
                            {!loadedImages[index] && <img alt={`image-${index}`} src={`https://api.e-fieldconnect.com/uploads/items/imageItem_${image}.jpg`}  />}
                          </div>
                        ))}
                        {loading && Array.from({ length: 15 }).map((_, index) => (
                          <Skeleton key={index} width={200} height={200} />
                        ))}
                {/* {loading && Array.from({ length: 20 }).map((_, index) => (
                  <Skeleton key={index} width={200} height={200} />
                ))} */}


                  {/* {images.map((image, index) => (
                    <img onClick={() => handleShowModal(image)} key={index} src={`https://api.e-fieldconnect.com/uploads/items/imageItem_${image}.jpg`} alt={`image-${index}`} />
                  ))} */}
                </div>
          </InfiniteScroll>
        </Card.Body>
    </Card>   
  

  <Modal show={showModal} onHide={handleCloseModal} animation={false}>
      <Modal.Header closeButton>
        <h6>{Array.isArray(modalContent) ? 'Photo Gallery' : 'Photo'}</h6>
      </Modal.Header>
      <Modal.Body>
        {
          Array.isArray(modalContent) && modalContent.length > 1 ? (
            <Carousel>
              {modalContent.map((pt, index) => (
                  <img src={`https://api.e-fieldconnect.com/uploads/items/imageItem_${pt}.jpg`} alt={`Photo ${index}`} />
              ))}
            </Carousel>
          ) : (
            <img src={`https://api.e-fieldconnect.com/uploads/items/imageItem_${modalContent}.jpg`} alt="Photo" />
          )
        }
      </Modal.Body>
    </Modal>
    
  </>
)
 
};



 